import {action, observable} from 'mobx';
import FetchProvider from "../../../shared/RequestProvider";

class TodayCourseStore {
  @observable loading = false;
  @observable todayCourse = [];
  
  @action fetchTodayCourses = () => {
    this.loading = true;
    return FetchProvider.get('/api/classrecord/today/')
      .then((res) => {
        this.todayCourse = res.data;
        this.loading = false;
      });
  };
  
}

export default new TodayCourseStore();
