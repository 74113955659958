import React from 'react';
import {Col, Divider, Row, Table} from 'antd';
import {observer} from "mobx-react";
import ClassStatStore from '../stores/ClassStatStore';

const columns = [{
  title: '月份',
  dataIndex: 'label',
  key: 'label',
}, {
  title: '课时数',
  dataIndex: 'hours',
  key: 'hours',
}];


@observer
class ClassOverview extends React.Component {
  componentDidMount = () => {
    const {classId} = this.props;
    ClassStatStore.fetchClassStat(classId);
  };

  render() {
    return (
      <Row>
        <Col span={24}>
          <h2>课程统计</h2>
        </Col>
        <Divider/>
        <Col span={24}>
          <Table
            rowKey="label"
            loading={ClassStatStore.loading}
            dataSource={ClassStatStore.classMonthlyStat}
            columns={columns}
            pagination={false}
          />
        </Col>
      </Row>
    );
  }
}

export default ClassOverview;
