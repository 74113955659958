import React from 'react';
import {Row, Col, Button} from 'antd';

import TeacherList from "../components/TeacherList";
import TeacherStore from "../stores/TeacherStore";

class TeacherListContainer extends React.Component {

  render() {
    return (
      <Row>
        <Col span={12}>
          <h1>教师列表</h1>
        </Col>
        <Col span={12}>
          <div style={{position: 'absolute', right: 0, top: 10}}>
            <Button onClick={() => {TeacherStore.fetchTeacherList()}}>刷新</Button>&nbsp;&nbsp;
            <a href="/admin/teachers/teacher/add/"
               target="_blank">
              <Button type="primary">添加</Button></a>
          </div>
        </Col>
        <Col span={24}>
          <TeacherList/>
        </Col>
      </Row>

    )
  }
}

export default TeacherListContainer;
