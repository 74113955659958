export const arraySum = (arr) => {
  let sum = 0;
  arr.forEach((val) => {
    sum += val;
  }, 0);
  return sum;
};

export const getUrlLoader = () => {
  return encodeURI(window.location.href.replace(window.location.host, '').replace('http://', '').replace('https://', ''));
};

export const translateWeekName = (weekName) => {
  const dict = {
    Sun: '星期日',
    Mon: '星期一',
    Tue: '星期二',
    Wed: '星期三',
    Thu: '星期四',
    Fri: '星期五',
    Sat: '星期六',
  };
  return dict[weekName];
};

export const getWeekPrompt = (weekValue) => {
  const dic = {
    per_week: '每周',
    two_week: '每两周',
    per_month: '每个月'
  };
  return dic[weekValue];
};

export const intToHourMinute = (val) => {
  val = parseInt(val);
  return `${
    `0${parseInt(val / 60)}`.slice(-2)}: ${`0${parseInt(val % 60)}`.slice(-2)
    }`;
}
