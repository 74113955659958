import React from 'react';
import {observer} from 'mobx-react';
import {Form, Input, Modal, Spin,} from 'antd';

import ClassRecordFeedbackStore from '../stores/ClassRecordFeedbackStore';
import ClassRecordStore from '../stores/ClassRecordStore';


@observer
class ClassRecordFeedbackModal extends React.Component {

  handleOk = () => {
    const {feedbackInfo} = ClassRecordFeedbackStore;
    ClassRecordFeedbackStore.updateClassRecordFeedback(feedbackInfo.feedback)
      .then(() => {
        ClassRecordFeedbackStore.reset();
        ClassRecordStore.refreshClassRecordList();
      });
  };

  handleCancel = () => {
    ClassRecordFeedbackStore.reset();
  };

  handleTextAreaChange = (e) => {
    ClassRecordFeedbackStore.feedbackInfo.feedback = e.target.value;
  };

  render() {
    const {loading, feedbackInfo, modalVisible} = ClassRecordFeedbackStore;

    return (
      <Modal
        confirmLoading={loading}
        visible={modalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        destroyOnClose
      >
        {
          feedbackInfo &&
          <h3>{feedbackInfo.class_name} - {feedbackInfo.name}(第{feedbackInfo.order}次)</h3>
        }
        <Form onSubmit={this.handleOk}>
          <Form.Item label="反馈">
            <Spin spinning={loading}>
              <Input.TextArea
                value={feedbackInfo.feedback}
                onChange={this.handleTextAreaChange}
                placeholder="请输入反馈"
              />
            </Spin>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default ClassRecordFeedbackModal;
