import React from 'react';

import { action, observable } from 'mobx';
import FetchProvider from "../../../shared/RequestProvider";
import {Select} from "antd";

class ClassroomStore{
  @observable isCreate = false;
  @observable loading = false;
  @observable loadingModal = false;
  @observable showClassroomEditModal = false;
  @observable pagination = {
    total: 0,
    current: 1,
    next: null,
    previous: null,
    pageSize: 1,
  };
  @observable classroomOptions = [];
  @observable classroomList = [];
  @action fetchClassroomList = (location_id) => {
    this.loading = true;
    const url = location_id
      ? `/api/classroom/?location=${location_id}` : '/api/classroom/';
    return FetchProvider.get(url)
      .then((res) => {
        if(res.data){
          this.classroomList = res.data;
          this.loading = false;
        }
      });
  };
  @action fetchClassroomOptions = (location_id) => {
    // TODO: 将班级对应校区改为ManyToManyField
    this.loading = true;
    return this.fetchClassroomList()
      .then(() => {
        this.classroomOptions = [];
        for (let item of this.classroomList) {
          this.classroomOptions.push(
            <Select.Option key={item.id} value={item.id}>{item.full_name}</Select.Option>
          )
        }
        this.classroomOptions.push(
          <Select.Option key={-1} value={null}>无</Select.Option>
        );
      });
  };
  @action getClassroomNameAndCapacityById = (classroom_id) => {
    for(let item of this.classroomList){
      if (item.id === classroom_id) {
        return `${item.name}(${item.capacity})`;
      }
    }
    return '';
  };

  @observable classroomDetail = {};
  @action fetchClassroom = (classroom_id) => {
    this.loadingModal = true;
    return FetchProvider.get(`/api/classroom/${classroom_id}/`)
      .then((res) => {
        if(res.data){
          this.classroomDetail = res.data;
          this.loadingModal = false;
        }
      });
  };
  @action updateClassroom = (classroom_id, update_data) => {
    this.loadingModal = true;
    return FetchProvider.patch(`/api/classroom/${classroom_id}/`, update_data)
      .then((res) => {
        if(res.data) {
          this.classroomDetail = res.data;
          this.loadingModal = false;
        }
      });
  };
  @action createClassroom = (classroom_data) => {
    this.loadingModal = true;
    return FetchProvider.post('/api/classroom/', classroom_data)
      .then((res) => {
        if(res.data) {
          this.classroomDetail = res.data;
          this.loadingModal = false;
        }
      });
  };
  @action deleteClassroom = (classroom_id) => {
    return FetchProvider.delete(`/api/classroom/${classroom_id}`);
  };
}

export default new ClassroomStore();
