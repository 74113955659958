import React from 'react';
import {Route, Switch} from 'react-router-dom';

import DashboardHomeContainer from "./containers/DashboardHomeContainer";

class DashboardIndex extends React.Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route path={`${match.url}`} component={DashboardHomeContainer}/>
      </Switch>
    );
  }
}

export default DashboardIndex;
