export const ClassRecordChoices = {
  LISTEN: 'LISTEN',
  READ: 'READ',
  SPEAK: 'SPEAK',
  WRITE: 'WRITE',
  ALEVEL_MATH: 'ALEVEL_MATH',
  ALEVEL_PHYSICS: 'ALEVEL_PHYSICS',
  ALEVEL_CHEMISTRY: 'ALEVEL_CHEMISTRY',
  ALEVEL_ECONOMICS: 'ALEVEL_ECONOMICS',
  NULL: 'NULL',
};

export const ClassRecordChoiceNames = {
  LISTEN: '听力',
  READ: '阅读',
  SPEAK: '口语',
  WRITE: '写作',
  ALEVEL_MATH: 'A-Level 数学',
  ALEVEL_PHYSICS: 'A-Level 物理',
  ALEVEL_CHEMISTRY: 'A-Level 化学',
  ALEVEL_ECONOMICS: 'A-Level 经济',
  NULL: '无',
};
