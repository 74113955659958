import React from 'react';
import {observer} from "mobx-react";

import TodayCourseStore from "../stores/TodayCourseStore";

import '../styles/TodayCourseList.css';
import GenericClassRecordList from "../../class/components/GenericClassRecordList";


@observer
class TodayCourseList extends React.Component {
  componentWillMount = () => {
    TodayCourseStore.fetchTodayCourses();
  };

  render() {
    return (
      <GenericClassRecordList
        loading={TodayCourseStore.loading}
        courseRecords={TodayCourseStore.todayCourse}
      />
    );
  }
}

export default TodayCourseList;
