import {action, observable} from 'mobx';

import FetchProvider from "../../../shared/RequestProvider";

class StudentDetailStore {
  @observable loading = true;

  @observable studentCoursesDetail = [];
  @observable studentCourseRecordsDetail = [];
  @observable studentFinishedCourseRecordsDetail = [];
  @action fetchStudentCourses = (student_id) => {
    this.loading = true;
    return FetchProvider.get(`/api/student/${student_id}/course/`)
      .then((res) => {
        if (res.data) {
          const data = res.data;
          this.studentCoursesDetail = data.course_records.courses;
          this.studentCourseRecordsDetail = data.course_records.course_records;
          this.studentFinishedCourseRecordsDetail = data.course_records.finished_course_records;
          this.loading = false;
        }
      });
  }
}

export default new StudentDetailStore();
