import React from 'react';

import {action, observable} from 'mobx';
import FetchProvider from "../../../shared/RequestProvider";
import {Select} from 'antd';

class LocationStore {
  @observable loading = false;
  @observable pagination = {
    total: 0,
    current: 1,
    next: null,
    previous: null,
    pageSize: 1,
  };
  @observable locationList = [];
  @action fetchLocationList = (page = 1) => {
    this.loading = true;
    return FetchProvider.get(`/api/location/?page=${page}`)
      .then((res) => {
        if (res.data) {
          const data = res.data;
          this.locationList = data.results;
          this.pagination = {
            total: data.count,
            current: page,
            next: data.next,
            previous: data.previous,
            pageSize: 10
          };
          this.loading = false;
        }
      });
  };

  @observable locationDetail = {};
  @action fetchLocation = (location_id) => {
    this.loading = true;
    return FetchProvider.get(`/api/location/${location_id}/`)
      .then((res) => {
        if (res.data) {
          this.locationDetail = res.data;
          this.loading = false;
        }
      });
  };
  @action updateLocation = (location_id, update_data) => {
    this.loading = true;
    return FetchProvider.patch(`/api/location/${location_id}/`, update_data)
      .then((res) => {
        if (res.data) {
          this.locationDetail = res.data;
          this.loading = false;
        }
      });
  };

  @observable locationOptions = [];
  @action fetchLocationOptions = () => {
    return FetchProvider.get('/api/location/all/')
      .then((res) => {
        if (res.data) {
          this.locationOptions = [];
          for (let item of res.data) {
            this.locationOptions.push(
              <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
            )
          }
        }
      });
  }
}

export default new LocationStore();
