import {action, observable} from 'mobx';
import FetchProvider from "../../../shared/RequestProvider";

import {message} from 'antd';


class ClassRecordBatchCreateStore {
  @observable showModal = false;
  @observable classRecordsToCreate = [];

  @observable conflictModalVisible = false;
  @observable allowSave = false;
  @observable conflictClassRecords = {
    classroom: [],
    teacher: [],
    class: [],
  };

  @action batchCheck = (data) => {
    return FetchProvider.post('/api/classrecord/batch_check/', {class_records: data})
      .then((res) => {
        this.conflictClassRecords = res.data;
        if (
          this.conflictClassRecords.classroom.length > 0 ||
          this.conflictClassRecords.teacher.length > 0 ||
          this.conflictClassRecords.class.length > 0
        ) {
          this.conflictModalVisible = true;
          this.allowSave = false;
          message.warning('存在冲突课程！请注意！');
        } else {
          this.allowSave = true;
          message.success('检查通过，暂无冲突。');
        }
      });
  };

  @action batchCreate = (data) => {
    return FetchProvider.post('/api/classrecord/batch_create/', {class_records: data});
  };
}

export default new ClassRecordBatchCreateStore();
