import React from 'react';
import {observer} from 'mobx-react';

import {
  Form, Icon, Input, Button, Row, Col, Card, message
} from 'antd';
import TeacherStore from "../stores/TeacherStore";

const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

@observer
class TeacherEditor extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        TeacherStore.updateTeacher(this.props.teacherId, values)
          .then(() => {
            message.success('Update success!');
          })
          .else((res) => {
            message.error(`Update failed! Msg: ${res.response.toString()}`);
          });
      }
    });
  };

  render() {
    const {
      getFieldDecorator, getFieldsError,
    } = this.props.form;

    // Only show error after a field is touched.
    return (

      <Form
        layout="vertical"
        onSubmit={this.handleSubmit}>
        <Card
          title="教师编辑"
          loading={TeacherStore.loading}
          extra={<Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError()) || TeacherStore.loading}
          >
            Save
          </Button>}
        >
          <Row gutter={{sm: 16, md: 24}}>
            <Col sm={24} md={12}>
              <FormItem label="Teacher ID">
                {getFieldDecorator('teacher_id', {
                  initialValue: TeacherStore.teacherDetail.teacher_id,
                  rules: [{required: true, message: ''}],
                })(
                  <Input prefix={<Icon type="idcard" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Username"
                         disabled={true}/>
                )}
              </FormItem>

              <FormItem label="Name">
                {getFieldDecorator('name', {
                  initialValue: TeacherStore.teacherDetail.name,
                  rules: [{required: true, message: 'Please input teacher\'s name!'}],
                })(
                  <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Name"/>
                )}
              </FormItem>

              <FormItem label="English name">
                {getFieldDecorator('eng_name', {
                  initialValue: TeacherStore.teacherDetail.eng_name,
                })(
                  <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="English name"/>
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>

              <FormItem label="Phone">
                {getFieldDecorator('phone', {
                  initialValue: TeacherStore.teacherDetail.phone,
                })(
                  <Input prefix={<Icon type="phone" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Phone"/>
                )}
              </FormItem>

              <FormItem label="Email">
                {getFieldDecorator('email', {
                  initialValue: TeacherStore.teacherDetail.email,
                })(
                  <Input prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Email"/>
                )}
              </FormItem>

              <FormItem label="Register date">
                {getFieldDecorator('register_date', {
                  initialValue: TeacherStore.teacherDetail.register_date,
                  rules: [{required: true, message: ''}],
                })(
                  <Input prefix={<Icon type="code" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Register date"
                         disabled={true}/>
                )}
              </FormItem>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

const WrappedTeacherEditor = Form.create()(TeacherEditor);

export default WrappedTeacherEditor;
