import {action, observable} from 'mobx';

import FetchProvider from "../../../shared/RequestProvider";

class ClassroomDetailStore {
  @observable loading = true;
  @observable modalVisible = false;

  @observable classroomCoursesDetail = [];
  @observable classroomCourseRecordsDetail = [];
  @observable classroomFinishedCourseRecordsDetail = [];
  @action fetchClassroomCourses = (classroom_id) => {
    this.loading = true;
    return FetchProvider.get(`/api/classroom/${classroom_id}/course/`)
      .then((res) => {
        if (res.data) {
          const data = res.data;
          this.classroomCoursesDetail = data.course_records.courses;
          this.classroomCourseRecordsDetail = data.course_records.course_records;
          this.classroomFinishedCourseRecordsDetail = data.course_records.finished_course_records;
          this.loading = false;
        }
      });
  };

  @observable classroomHeatMap = [];
  @action fetchClassroomHeatMap = (classroomId) => {
    this.loading = true;
    return FetchProvider.get(`/api/classroom/${classroomId}/heat_map/`)
      .then((res) => {
        this.loading = false;
        this.classroomHeatMap = res.data;
      });
  };
}

export default new ClassroomDetailStore();
