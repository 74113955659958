import {observable, action} from "mobx";
import {Button, message} from "antd";

import FetchProvider from "../../../shared/RequestProvider";
import React from "react";


class ClassSignInStore {
  @observable loading = false;
  @observable visible = false;
  @observable targetClassRecord = 0;
  @observable students = [];

  @action getStudentActions = (class_record_id, student_id, status) => {
    return (
      <Button.Group>
        <Button
          key={1} type={status === 1 ? 'primary' : ''}
          onClick={() => {
            this.setStudentStatus(class_record_id, student_id, 1);

          }}
        >到课</Button>
        <Button
          key={2} type={status === 2 ? 'primary' : ''}
          onClick={() => {
            this.setStudentStatus(class_record_id, student_id, 2);
          }}
        >迟到</Button>
        <Button
          key={3} type={status === 3 ? 'primary' : ''}
          onClick={() => {
            this.setStudentStatus(class_record_id, student_id, 3);
          }}
        >旷课</Button>
      </Button.Group>
    )
  };

  @action fetchStudents = (class_record_id) => {
    this.loading = true;
    return FetchProvider.get(`/api/classrecord/${class_record_id}/get_students/`)
      .then((res) => {
        this.students = [];
        for (let item of res.data) {
          item['action'] = this.getStudentActions(class_record_id, item.student_id, item.status);
          this.students.push(item);
        }
        this.loading = false;
      });
  };

  @action setStudentStatus = (class_record_id, student_id, status) => {
    const new_students = [];
    for (let item of this.students){
      if (item.student_id === student_id){
        item.action = this.getStudentActions(class_record_id, student_id, status);
      }
      new_students.push(item);
    }
    this.students = new_students;

    this.loading = true;
    return FetchProvider.get(
      `/api/classrecord/${class_record_id}/set_student/?student_id=${student_id}&status=${status}`
    )
      .then((res) => {
        if (res.data.status === 1) {
          message.success('设置成功');
        } else {
          message.error('设置失败');
        }
        this.fetchStudents(class_record_id);
      })
  };
}

export default new ClassSignInStore();
