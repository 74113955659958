import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from 'antd';
// Custom component
import DashboardIndex from "../apps/dashboard";
import StudentIndex from "../apps/student/index";
import TeacherIndex from "../apps/teacher/index";
import CampusIndex from "../apps/campus/index";
import ClassIndex from "../apps/class";
// Antd components
const {Content} = Layout;

class AppRouter extends React.Component {
  render() {
    return (
      <Content style={{margin: '24px 16px 0'}}>
        <Switch>
          <Route path='/dashboard' component={DashboardIndex}/>
          <Route path='/student' component={StudentIndex}/>
          <Route path='/teacher' component={TeacherIndex}/>
          <Route path='/location' component={CampusIndex}/>
          <Route path='/class' component={ClassIndex}/>
          <Route component={() => {return <Redirect to='/dashboard' />;}}/>
        </Switch>
      </Content>
    );
  }
}

export default AppRouter;