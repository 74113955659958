import {action, observable} from 'mobx';
import DataSet from "@antv/data-set";

import FetchProvider from "../../../shared/RequestProvider";

const {DataView} = DataSet;


class ClassroomMonitorStore {
  @observable loading = false;
  @observable classroomDashboard = {};
  @observable resolvedClassroomDistribution = [];
  @observable classroomCount = 0;
  @observable lowUsageRateClassrooms = [];

  @action fetchClassroomDashboard = () => {
    this.loading = true;
    return FetchProvider.get('/api/dashboard/get_classroom_dashboard/')
      .then((res) => {
        this.classroomDashboard = res.data;
        this.resolveClassroomDistribution();
        this.resolveLowUsageClassrooms();
        this.loading = false;
      });
  };
  @action resolveLowUsageClassrooms = () => {
    this.lowUsageRateClassrooms = [];
    if (this.classroomDashboard.classroom_usage_rates) {
      const classrooms = this.classroomDashboard.classroom_usage_rates;
      for (const classroom of classrooms) {
        const rates = classroom['rates'];
        for (const date in rates) {
          if (rates[date] < 0.4) {
            this.lowUsageRateClassrooms.push({
              name: classroom['name'],
              date,
              id: classroom['instance']['id'],
              location: classroom['instance']['location'],
              rate: rates[date]
            })
          }
        }
      }
    }
  };
  @action resolveClassroomDistribution = () => {
    const dv = new DataView();
    this.resolvedClassroomDistribution = [];
    this.classroomCount = 0;
    if (this.classroomDashboard.campus_info) {
      for (const location of this.classroomDashboard.campus_info) {
        let count = 0;
        if (location && location.classrooms) {
          count = location.classrooms.length;
        } else {
          count = 0;
        }
        this.resolvedClassroomDistribution.push({
          location: location.name, count
        });
        this.classroomCount += count;
      }
      dv.source(this.resolvedClassroomDistribution).transform({
        type: "percent",
        field: "count",
        dimension: "location",
        as: "percent"
      });
      this.resolvedClassroomDistribution = dv;
    }
  };

}

export default new ClassroomMonitorStore();
