import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import './App.css';

import LayoutWithSider from "./layout/LayoutWithSider";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <LayoutWithSider/>
        </Router>
      </div>
    );
  }
}

export default App;
