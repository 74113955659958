import React from 'react';
import {Link} from 'react-router-dom';
import {Table, Card, Tabs} from "antd";
import {observer} from "mobx-react";


import TeacherDetailStore from "../stores/TeacherDetailStore";
import CourseCalendar from "../../class/components/CourseCalendar";
import GenericClassRecordList from "../../class/components/GenericClassRecordList";


const courseColumns = [{
  title: '课程编号', key: 'class_no',
  render: (item) => <Link
    to={`/class/${item.class_id}`}>{item.class_no}</Link>
}, {
  title: '课程名',
  dataIndex: 'name',
}, {
  title: '上课地点',
  dataIndex: 'location_name',
}];

@observer
class TeacherCourses extends React.Component {
  componentWillMount() {
    TeacherDetailStore.fetchTeacherCourses(this.props.teacherId);
  }

  render() {
    return (
      <div>
        <Card
          size="large"
          title="已开课程"
          style={{width: '100%'}}
        >
          <Table
            rowKey={item => item.class_id}
            columns={courseColumns}
            dataSource={TeacherDetailStore.teacherCoursesDetail}
            loading={TeacherDetailStore.loading}
            pagination={false}
          />
        </Card>
        <br/><br/>
        <Card
          size="large"
          title="上课安排"
          style={{width: '100%'}}
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="日历" key="1">
              <CourseCalendar
                showInterval
                dailyCourseList={TeacherDetailStore.teacherCourseRecordsDetail.concat(
                  TeacherDetailStore.teacherFinishedCourseRecordsDetail)}
                loading={TeacherDetailStore.loading}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="未上课程" key="2">
              <GenericClassRecordList
                courseRecords={TeacherDetailStore.teacherCourseRecordsDetail}
                loading={TeacherDetailStore.loading}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="已上课程" key="3">
              <GenericClassRecordList
                courseRecords={TeacherDetailStore.teacherFinishedCourseRecordsDetail}
                loading={TeacherDetailStore.loading}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
}

export default TeacherCourses;
