import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Card, Table, Tabs} from "antd";
import {observer} from "mobx-react";
import {
  Chart,
  Geom,
  Axis,
  Tooltip,
} from "bizcharts";

import TeacherStore from "../stores/TeacherStore";
import TeacherOverviewStore from '../stores/TeacherOverviewStore';



@observer
class TeacherOverview extends React.Component {
  componentDidMount = () => {
    const {teacherId} = this.props;
    TeacherOverviewStore.fetchTeacherOverview(teacherId);
  };

  render() {
    const {teacherId} = this.props;
    const chartCols = {
      time: {
        min: 0
      },
    };
    const tableCols = [{
      title: '课程',
      dataIndex: '',
      key: 'name',
      render: item => <Link to={`/class/${item.id}`}>{item.name}</Link>
    }, {
      title: '课时统计',
      dataIndex: '',
      key: 'time',
      render: item => <p>{(item.time / 60).toFixed(1)} 小时</p>
    }];
    return (
      <div>
        <Card title="近期课时统计" className="teacher-overview-course-stat">
          <Chart
            height={400}
            data={TeacherOverviewStore.teacherOverview.monthly_course_stat}
            scale={chartCols} forceFit
          >
            <Axis name="month"/>
            <Axis
              name="time"
              label={{
                formatter: val => `${val} 分钟`
              }}
            />
            <Tooltip
              crosshairs={{
                type: "y"
              }}
              itemTpl={'<li data-index={index}>'
              + '<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>'
              + '上课时间 {value} 分钟'
              + '</li>'}
            />
            <Geom type="line" position="month*time" size={2}/>
            <Geom
              type="point"
              position="month*time"
              size={4}
              shape={"circle"}
              style={{
                stroke: "#fff",
                lineWidth: 1
              }}
            />
          </Chart>
          <Tabs defaultActiveKey="1">
            {TeacherOverviewStore.teacherOverview.monthly_course_stat.map(item =>
              <Tabs.TabPane tab={item.month} key={item.month}>
                <Table
                  columns={tableCols}
                  pagination={false}
                  dataSource={item.course_stat}
                />
              </Tabs.TabPane>
            )}
          </Tabs>

        </Card>
        <Card title="课表信息下载">
          <a
            href={`/api/teacher/${teacherId}/get_schedule/`}
            download={`教师 ${TeacherStore.teacherDetail.name} 开课课表`}
          ><Button type='primary'>下载未来课表</Button></a>
          &nbsp;&nbsp;
          <a
            href={`/api/teacher/${teacherId}/get_schedule/?past=1`}
            download={`${TeacherStore.teacherDetail.name} 开课课表`}
          ><Button type=''>下载全部课表</Button></a>
        </Card>
      </div>
    );
  }
}

export default TeacherOverview;
