import React from 'react';
import {Row, Col, Tabs} from 'antd';
import {observer} from "mobx-react";

import TodayCourseList from "../components/TodayCourseList";
import MonthCalendar from "../components/MonthCalendar";
import ClassroomMonitorContainer from "./ClassroomMonitorContainer";
import DetailedClassroomUsage from "../components/DetailedClassroomUsage";


@observer
class DashboardHomeContainer extends React.Component{
  render(){
    return (
      <Row>
        <Col span={24}>
          <h1>总览</h1>
        </Col>
        <Col span={24}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="当天课程" key="1">
              <TodayCourseList />
            </Tabs.TabPane>
            <Tabs.TabPane tab="课程日历" key="2">
              <MonthCalendar />
            </Tabs.TabPane>
            <Tabs.TabPane tab="教室统计" key="3">
              <ClassroomMonitorContainer />
            </Tabs.TabPane>
            <Tabs.TabPane tab="教室明细" key="4">
              <DetailedClassroomUsage />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>

    )
  }
}

export default DashboardHomeContainer;
