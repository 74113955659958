import { action, observable } from 'mobx';
import FetchProvider from "../../../shared/RequestProvider";

class StudentStore{
  @observable loading = false;
  @observable pagination = {
    total: 0,
    current: 1,
    next: null,
    previous: null,
    pageSize: 1,
  };
  @observable studentList = [];
  @observable searchStudentType = '';
  @observable searchStudentStatus = '1';

  @action fetchStudentList = (page=1) => {
    this.loading = true;
    const params = {
      page,
      type: this.searchStudentType,
      status: this.searchStudentStatus,
    };
    return FetchProvider.get('/api/student/', {params})
      .then((res) => {
        if(res.data){
          const data = res.data;
          this.studentList = data.results;
          this.pagination = {
            total: data.count,
            current: page,
            next: data.next,
            previous: data.previous,
            pageSize: 10
          };
          this.loading = false;
        }
      });
  };

  @observable searchText = '';
  @action searchStudents = (dataIndex, searchText) => {
    this.loading = true;
    return FetchProvider.get(`/api/student/?${dataIndex}=${searchText}`)
      .then((res) => {
        if(res.data){
          const data = res.data;
          this.studentList = data.results;
          this.pagination = {
            total: data.count,
            current: 1,
            next: data.next,
            previous: data.previous,
            pageSize: 10
          };
          this.loading = false;
        }
      });
  };

  @observable studentDetail = {};
  @action fetchStudent = (student_id) => {
    this.loading = true;
    return FetchProvider.get(`/api/student/${student_id}/`)
      .then((res) => {
        if(res.data){
          this.studentDetail = res.data;
          this.loading = false;
        }
      });
  };
  @action updateStudent = (student_id, update_data) => {
    this.loading = true;
    return FetchProvider.patch(`/api/student/${student_id}/`, update_data)
      .then((res) => {
        if(res.data) {
          this.studentDetail = res.data;
          this.loading = false;
        }
      });
  };

  @action deleteStudent = (student_id) => {
    return FetchProvider.delete(`/api/student/${student_id}/`);
  };

  @action setStudentStatus = (student_id, status) => {
    return FetchProvider.post(`/api/student/${student_id}/set_status/`, {status});
  };
}

export default new StudentStore();
