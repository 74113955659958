import React from 'react';
import {observer} from "mobx-react";
import {Link} from 'react-router-dom';
import {Button, Calendar, Badge, Popover, Tooltip} from "antd";

import Moment from 'moment';

import '../styles/CourseCalendar.css';

@observer
class CourseCalendar extends React.Component {
  unifyDailyCourseList = (dailyCourseList) => {
    dailyCourseList = dailyCourseList.slice().sort((x, y) => {
      return Moment(x).unix() - Moment(y).unix();
    });
    dailyCourseList = dailyCourseList.slice();
    const now = Moment().unix();
    for (let i in dailyCourseList) {
      if (!dailyCourseList[i]) {
        continue;
      }
      if (Moment(dailyCourseList[i].start_at).unix() < now) {
        dailyCourseList[i]['type'] = 'warning';
        dailyCourseList[i]['msg'] = '已上课';
        // } else if (Moment(dailyCourseList[i + 1].start_at).unix() >
        //   Moment(dailyCourseList[i + 1].end_at).unix()) {
        //   dailyCourseList[i + 1]['type'] = 'error';
        //   dailyCourseList[i + 1]['msg'] = '存在课程冲突';
      } else {
        dailyCourseList[i]['type'] = 'success';
        dailyCourseList[i]['msg'] = '等待上课';
      }
    }
    return dailyCourseList;
  };

  dailyCourseRender = (value) => {
    const {showInterval} = this.props;
    let {dailyCourseList} = this.props;
    let tmpEntAt = null;
    dailyCourseList = this.unifyDailyCourseList(dailyCourseList);

    const res = [];
    for (const item of dailyCourseList) {
      const lastTime = tmpEntAt;
      const startAt = Moment(item.start_at).local();
      const endAt = Moment(item.end_at).local();

      if (startAt.format('YYYY-MM-DD') === value.format('YYYY-MM-DD')) {
        if (
          showInterval && lastTime &&
          lastTime.format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
        ) {
          const interval = parseInt((startAt.unix() - lastTime.unix()) / 60);
          res.push(
            <li key={`${item.id}-interval-prompt`}>
              <Tooltip placement="right" title={<span>间隔 {interval} 分钟</span>}>
                <Badge status="default" text={`间隔 ${interval} 分钟`}/>
              </Tooltip>
            </li>
          );
        }
        res.push(
          <Popover
            key={item.id}
            placement="right"
            title={<div>
              <h3>{item.class_name}(第{item.order}次)</h3>
              <h3>{item.name}</h3>
            </div>}
            content={<div>
              <p>上课地点：{item.classroom_name}</p>
              <p>
                上课时间：{startAt.format('HH:mm')} -
                {endAt.format('HH:mm')}
              </p>
              <p>持续时间：{item.duration}</p>
              <p>任课老师：{item.teacher_name}</p>
              <p>上课学生：{item.student_names}</p>
              <Link to={`/class/${item.class_id}`}><Button type="primary">查看</Button></Link>
            </div>}>
            <li key={item.id}>
              <Badge status={item.type} text={
                `${item.class_name}(${startAt.format('HH:mm')})`
              }/>
            </li>
          </Popover>
        );
      }
      tmpEntAt = endAt;
    }
    return (
      dailyCourseList &&
      <ul className="events">
        {res}
      </ul>
    )
  };

  dateAsKeyDailyCourseRender = (value) => {
    const {dailyCourseList} = this.props;
    const todayCourseList = dailyCourseList[value.format('YYYY-MM-DD')];
    if (!todayCourseList){
      return
    }
    const res = [];
    for (const item of this.unifyDailyCourseList(todayCourseList)){
      const startAt = Moment(item.start_at).local();
      const endAt = Moment(item.end_at).local();
      res.push(
        <Popover
          key={item.id}
          placement="right"
          title={<div>
            <h3>{item.class_name}(第{item.order}次)</h3>
            <h3>{item.name}</h3>
          </div>}
          content={<div>
            <p>上课地点：{item.classroom_name}</p>
            <p>
              上课时间：{startAt.format('YYYY-MM-DD')} {startAt.format('HH:mm')} -
              {endAt.format('HH:mm')}
            </p>
            <p>持续时间：{item.duration}</p>
            <p>任课老师：{item.teacher_name}</p>
            <p>上课学生：{item.student_names}</p>
            <Link to={`/class/${item.class_id}`}><Button type="primary">查看</Button></Link>
          </div>}>
          <li key={item.id}>
            <Badge status={item.type} text={
              `${item.class_name}(${startAt.format('HH:mm')})`
            }/>
          </li>
        </Popover>
      );
    }
    return (
      dailyCourseList &&
      <ul className="events">
        {res}
      </ul>
    )
  };

  render() {
    const { dateAsKey } =  this.props;
    const dateCellRender = dateAsKey ? this.dateAsKeyDailyCourseRender : this.dailyCourseRender;
    const { onChange } = this.props;
    return (
      <Calendar
        dateCellRender={dateCellRender}
        onChange={onChange}
      />
    )
  }
}

export default CourseCalendar;
