import {observable, action} from "mobx";
import {message} from "antd";

import FetchProvider from "../../../shared/RequestProvider";

class StudentSelectStore {
  @observable loading = false;
  @observable students = [];
  @observable absentStudents = [];
  @observable lateStudents = [];

  @action fetchStudentsByClassId = (class_id) => {
    this.loading = true;
    return FetchProvider.get(`/api/class/${class_id}/get_students/`)
      .then((res) => {
        this.loading = false;
        this.students = res.data;
      });
  };

  @action removeStudentFromClass = (student_id, class_id) => {
    return FetchProvider.get(`/api/class/${class_id}/remove_student/?student_id=${student_id}`)
      .then((res) => {
        if(res.data.status === -1){
          message.warn('学生不在该课程');
        }
        else{
          message.success('删除成功');
        }
        this.loading = false;
      });
  };

  @action addStudentToClass = (student_id, class_id) => {
    return FetchProvider.get(`/api/class/${class_id}/add_student/?student_id=${student_id}`)
      .then((res) => {
        if(res.data.status === -1){
          message.warn('学生已经在该课程');
        }
        else{
          message.success('删除成功');
        }
        this.loading = false;
      });
  };

  @observable searchResult = [];
  @observable selectedStudent = -1;
  @action searchStudent = (query, class_id) => {
    this.loading = true;
    let url = '';
    if (class_id){
      url = `/api/class/search_student/?query=${query}&class_id=${class_id}`;
    }
    else{
      url = `/api/class/search_student/?query=${query}`;
    }
    return FetchProvider.get(url)
      .then((res) => {
        this.searchResult = res.data;
        this.loading = false;
      });
  };
}

export default new StudentSelectStore();
