import React from 'react';
import {Row, Col, Tabs, Divider} from 'antd';
import {observer} from "mobx-react";

import ClassRecordList from "../components/ClassRecordList";
import WrappedClassEditor from "../components/ClassEditor";
import WrappedClassRecordModal from "../components/ClassRecordModal";
import WrappedClassRecordBatchCreateModal from "../components/ClassRecordBatchCreateModal";
import ClassStudentSelectList from "../components/ClassStudentSelectList";
import ClassSignInModal from "../components/ClassSignInModal";
import ClassStore from "../stores/ClassStore";
import ClassSchedule from "../components/ClassSchedule";
import ClassRecordBatchCreateConflictModal from "../components/ClassRecordBatchCreateConflictModal";
import ClassRecordFeedbackModal from '../components/ClassRecordFeedbackModal';
import ClassOverview from '../components/ClassOverview';


@observer
class ClassEditorContainer extends React.Component {
  componentWillMount = () => {
    const {classId} = this.props.match.params;
    ClassStore.fetchClass(classId);
  };

  render() {
    const {classId} = this.props.match.params;

    return (
      <Row>
        <Col span={24}>
          <h3>{ClassStore.classDetail.name}</h3>
          <Divider/>
        </Col>
        <Col span={24}>
          <Tabs defaultActiveKey="1" tabPosition="left">
            <Tabs.TabPane tab="课程总览" key="1">
              <Col span={24}>
                <ClassOverview classId={classId}/>
                <br/><br/>
                <ClassSchedule classId={classId}/>
              </Col>
            </Tabs.TabPane>

            <Tabs.TabPane tab="课时安排" key="2">
              <Col span={24}>
                <ClassRecordList classId={classId}/>
                <ClassSignInModal/>
                <WrappedClassRecordModal/>
                <ClassRecordFeedbackModal/>
                <WrappedClassRecordBatchCreateModal/>
                <ClassRecordBatchCreateConflictModal/>
              </Col>
            </Tabs.TabPane>

            <Tabs.TabPane tab="上课学生" key="3">
              <Col span={24}>
                <ClassStudentSelectList classId={classId}/>
              </Col>
            </Tabs.TabPane>

            <Tabs.TabPane tab="课程编辑" key="4">
              <Col span={24}>
                <WrappedClassEditor classId={classId}/>
              </Col>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>

    )
  }
}

export default ClassEditorContainer;
