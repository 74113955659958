import axios from 'axios';
import {message} from 'antd';

import {getUrlLoader} from './utils';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
const FetchProvider = axios.create({
  baseURL: '',
  withCredentials: true
});

FetchProvider.interceptors.response.use((res) => {
    return res;
  }, (err) => {
    if (err.response.status === 403) {
      message.error('Not login');
      window.document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      window.location.replace(`/api-auth/login/?next=${getUrlLoader()}`);
    }
    if (err.response.status - 200 > 100) {
      message.error(`Error! ${err.response.status}: ${err.response.statusText}`);
    }
    return err;
  }
);

export default FetchProvider;
