import React from 'react';
import {observer} from "mobx-react";
import {Modal} from "antd";

import ClassSignInStore from "../stores/ClassSignInStore";
import StudentSelectList from "./StudentSelectList";


@observer
class ClassSignInModal extends React.Component{
  render() {
    return (
      <div>
        <Modal
          title="课程签到"
          footer={null}
          width={800}
          visible={ClassSignInStore.visible}
          loading={ClassSignInStore.loading}
          onCancel={() => {ClassSignInStore.visible = false;}}
          destroyOnClose
        >
          <StudentSelectList
            loading={ClassSignInStore.loading}
            dataSource={ClassSignInStore.students}
          />
        </Modal>
      </div>
    );
  }
}

export default ClassSignInModal;
