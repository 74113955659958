import React from 'react';
import {Row, Col, Button, Divider, Select} from 'antd';

import ClassList from "../components/ClassList";
import ClassStore from "../stores/ClassStore";


class ClassListContainer extends React.Component{
  render(){
    return (
      <Row>
        <Col span={12}>
          <h1>班级</h1>
        </Col>
        <Col span={12}>
          <div style={{position: 'absolute', right: 0, top: 10}}>
            <Select
              style={{ width: 100 }}
              placeholder="选择类型"
              onChange={(value) => {
                ClassStore.searchClassType = value.toString();
                ClassStore.fetchClassList();
              }}
            >
              <Select.Option value="">全部</Select.Option>
              <Select.Option value="i">雅思</Select.Option>
              <Select.Option value="t">托福</Select.Option>
              <Select.Option value="a">A-Level</Select.Option>
            </Select>
            <Divider type="vertical"/>
            <Button onClick={() => {ClassStore.fetchClassList(ClassStore.pagination.current)}}>刷新</Button>&nbsp;&nbsp;
            <a href="/admin/course/class/add/"
               target="_blank">
              <Button type="primary">添加</Button></a>
          </div>
        </Col>
        <Col span={24}>
          <ClassList/>
        </Col>
      </Row>

    )
  }
}

export default ClassListContainer;
