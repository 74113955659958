import React from 'react';
import Moment from 'moment';
import {observer} from "mobx-react";
import {Button, Col, Divider, Modal, Row, List} from "antd";

import ClassRecordBatchCreateStore from "../stores/ClassRecordBatchCreateStore";
import {translateWeekName} from "../../../shared/utils";


@observer
class ClassRecordBatchCreateConflictModal extends React.Component {
  handleCancel = () => {
    ClassRecordBatchCreateStore.conflictModalVisible = false;
  };

  getItemTitle = (item) => {
    return <a target='_blank' rel="noopener noreferrer" href={`/class/${item._class}`}>
      {`从${Moment(item.start_at).format('YYYY年MM月DD日')} ${translateWeekName(Moment(item.start_at).format('ddd'))} 开始上课，课程名为 ${item.class_name} 任课老师为 ${item.teacher_name}`}
    </a>;
  };

  getItemDescription = (item) => {
    return `上课地点为${item.classroom_full_name}，${Moment(item.start_at).format('HH:mm')}开始，持续${item.duration}分钟`;
  };

  getConflictRecordList = (data, type) => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              key={`type${item.id}`}
              title={this.getItemTitle(item)}
              description={this.getItemDescription(item)}
            />
          </List.Item>
        )}
      />
    )
  };

  render() {
    return (
      <Modal
        title='冲突情况'
        onCancel={this.handleCancel}
        visible={ClassRecordBatchCreateStore.conflictModalVisible}
        footer={[
          <Button type='primary' key='ok' onClick={this.handleCancel}>OK</Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <Divider orientation="left">教室冲突</Divider>
            {this.getConflictRecordList(
              ClassRecordBatchCreateStore.conflictClassRecords.classroom, 'classroom')}
          </Col>
          <Col span={24}>
            <Divider orientation="left">老师冲突</Divider>
            {this.getConflictRecordList(
              ClassRecordBatchCreateStore.conflictClassRecords.teacher, 'teacher')}
          </Col>
          <Col span={24}>
            <Divider orientation="left">课程冲突</Divider>
            {this.getConflictRecordList(
              ClassRecordBatchCreateStore.conflictClassRecords.class, 'class')}
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default ClassRecordBatchCreateConflictModal;
