import { action, observable } from 'mobx';
import FetchProvider from "../../../shared/RequestProvider";

class ClassStatStore {
  @observable loading = false;
  @observable classMonthlyStat = [];
  @action fetchClassStat = (class_id) => {
    this.loading = true;
    return FetchProvider.get(`/api/class/${class_id}/get_class_monthly_stat/`)
      .then((res) => {
        if(res.data){
          this.classMonthlyStat = res.data;
          this.loading = false;
        }
      });
  };
}

export default new ClassStatStore();
