import React from 'react';

import {List, Card, Button} from 'antd';
import {observer} from 'mobx-react';
import ClassroomStore from "../stores/ClassroomStore";
import ClassroomDetailStore from "../stores/ClassroomDetailStore";


@observer
class ClassroomList extends React.Component {
  componentDidMount() {
    ClassroomStore.fetchClassroomList(this.props.locationId);
  }

  showClassroomCreateModal = () => {
    ClassroomStore.isCreate = true;
    ClassroomStore.showClassroomEditModal = true;
  };

  showClassroomEditModal = (classroomId) => {
    ClassroomStore.isCreate = false;
    ClassroomStore.showClassroomEditModal = true;
    ClassroomStore.fetchClassroom(classroomId);
  };

  deleteClassroom = (classroomId) => {
    ClassroomStore.deleteClassroom(classroomId)
      .then(() => {
        ClassroomStore.fetchClassroomList(this.props.locationId);
      })
  };

  showClassroomDetailModal = (classroomId) => {
    ClassroomDetailStore.modalVisible = true;
    ClassroomDetailStore.fetchClassroomHeatMap(classroomId);
    ClassroomDetailStore.fetchClassroomCourses(classroomId);
  };

  render() {
    return (
      <Card
        title="教室列表"
        extra={<Button onClick={this.showClassroomCreateModal}>增加</Button>}
        loading={ClassroomStore.loading}
      >
        <List
          itemLayout="horizontal"
          dataSource={ClassroomStore.classroomList}
          renderItem={item => (
            <List.Item actions={[
              <Button type='primary' onClick={() => {
                this.showClassroomDetailModal(item.id)
              }}>详情</Button>,
              <Button type='' onClick={() => {
                this.showClassroomEditModal(item.id)
              }}>编辑</Button>,
              <Button type='danger' onClick={() => {
                this.deleteClassroom(item.id)
              }}>删除</Button>
            ]}>
              <List.Item.Meta
                title={item.name}
                description={`容纳人数：${item.capacity}`}
              />
            </List.Item>
          )}
        />
      </Card>
    )
  }
}

export default ClassroomList
