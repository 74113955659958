import React from 'react';
import { Row, Col } from 'antd';

import WrappedLocationEditor from "../components/LocationEditor";
import ClassroomList from "../components/ClassroomList";
import WrappedClassroomModal from "../components/ClassroomModal";
import ClassroomDetailModal from "../components/ClassroomDetailModal";

import '../styles.css';


class LocationEditorContainer extends React.Component{
  render(){
    const locationId = this.props.match.params.locationId;
    return (
      <Row>
        <Col span={24}>
          <WrappedLocationEditor locationId={locationId}/>
        </Col>
        <Col span={24}>
          <ClassroomList locationId={locationId}/>
          <WrappedClassroomModal/>
          <ClassroomDetailModal />
        </Col>
      </Row>

    )
  }
}

export default LocationEditorContainer;
