import React from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";

import {Card, Table} from 'antd';
import ClassroomMonitorStore from "../stores/ClassroomMonitorStore";


@observer
class ClassroomLowUsageList extends React.Component {
  render() {
    const columns = [{
      title: '教室',
      key: 'name',
      dataIndex: '',
      render: (item) => <Link to={`/location/${item.location}`}>{item.name}</Link>
    }, {
      title: '日期',
      key: 'date',
      dataIndex: 'date'
    }, {
      title: '使用率',
      key: 'rate',
      dataIndex: '',
      render: (item) => <p>{item.rate * 100}%</p>
    }];

    return (
      <Card
        loading={ClassroomMonitorStore.loading}
        title="低使用率教室预警"
      >
        <Table
          columns={columns}
          rowKey={item => item.id.toString() + item.date}
          dataSource={ClassroomMonitorStore.lowUsageRateClassrooms}
        />
      </Card>
    );
  }
}

export default ClassroomLowUsageList;
