import React from 'react';
import {observer} from 'mobx-react';

import {
  Modal, Button, Form, Row, Col, List, Select, DatePicker, Divider, Input, Icon, Tooltip
} from 'antd';
import ClassRecordStore from "../stores/ClassRecordStore";
import TeacherStore from "../../teacher/stores/TeacherStore";
import ClassroomStore from "../../campus/stores/ClassroomStore";
import ClassRecordBatchCreateStore from "../stores/ClassRecordBatchCreateStore";
import {getWeekPrompt, translateWeekName} from "../../../shared/utils";
import ClassStore from "../stores/ClassStore";
import moment from "moment";
import {ClassRecordChoiceNames, ClassRecordChoices} from "../constants";

const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

@observer
class ClassRecordBatchCreateModal extends React.Component {
  componentWillMount = () => {
    TeacherStore.fetchTeacherOptions();
    ClassRecordBatchCreateStore.allowSave = false;
  };

  getItemTitle = (item) => {
    return `从${item.start_at.format('YYYY年MM月DD日')} ${translateWeekName(item.start_at.format('ddd'))} 开始上课，课程名为 ${item.name} 任课老师为${TeacherStore.getTeacherNameById(item.teacher)}，${getWeekPrompt(item.frequency)}上一次课，共上${item.times}次课`;
  };

  getItemDescription = (item) => {
    return `上课地点为${ClassroomStore.getClassroomNameAndCapacityById(item.classroom)}，${item.start_at.format('HH:mm')}开始，持续${item.duration}分钟`;
  };

  handleCancel = () => {
    ClassRecordBatchCreateStore.showModal = false;
  };

  addBatchRecord = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        ClassRecordBatchCreateStore.classRecordsToCreate.push(values);
      }
    });
  };

  processData = () => {
    const res = [];
    for (let item of ClassRecordBatchCreateStore.classRecordsToCreate) {
      let _fre = 1;
      switch (item.frequency) {
        case 'per_week':
          _fre = 1;
          break;
        case 'two_week':
          _fre = 2;
          break;
        case 'per_month':
          _fre = 4;
          break;
        default:
          break;
      }
      for (let i = 0; i < item.times; i++) {
        const start_at = moment(item.start_at.format());
        res.push({
          _class: ClassStore.classDetail.class_id,
          name: item.name,
          start_at: (start_at.add(7 * i * _fre, 'days')).utc().format(),
          duration: item.duration,
          teacher: item.teacher,
          classroom: item.classroom,
        })
      }
    }
    return res
  };

  handleCheck = () => {
    ClassRecordBatchCreateStore.batchCheck(this.processData());
  };

  handleCreate = () => {
    ClassRecordBatchCreateStore.batchCreate(this.processData())
      .then(() => {
        ClassRecordStore.fetchClassRecordList(ClassStore.classDetail.class_id);
        ClassRecordBatchCreateStore.showModal = false;
        ClassRecordBatchCreateStore.classRecordsToCreate = [];
      });
  };

  render() {
    const inputValueWidth = 200;
    const {
      getFieldDecorator, getFieldsError,
    } = this.props.form;

    return (
      <Modal
        width={650}
        title="批量添加课程"
        onOk={this.handleCreate}
        onCancel={this.handleCancel}
        visible={ClassRecordBatchCreateStore.showModal}
        footer={[
          <Button key="back" onClick={this.handleCancel}>取消</Button>,
          <Button key="check" type="danger" onClick={this.handleCheck}>检查</Button>,
          <Button
            key="submit" type="primary" loading={ClassRecordStore.loadingModal}
            onClick={this.handleCreate} disabled={
            !ClassRecordBatchCreateStore.allowSave || hasErrors(getFieldsError())}>
            创建
          </Button>,
          <Tooltip key="tooltip" placement="top" title="请先检查再保存">
            &nbsp;&nbsp;<Icon type="question-circle"/>
          </Tooltip>,
        ]}
        destroyOnClose={true}
      >
        <Form
          layout="inline"
          onSubmit={this.addBatchRecord}
        >
          <Row gutter={12}>
            <Col sm={24}>
              <FormItem label="开始">
                {getFieldDecorator('start_at', {
                  rules: [{required: true, message: '请输入课程开始时间'}],
                })(
                  <DatePicker
                    showTime={{format: 'HH:mm'}}
                    format="YYYY-MM-DD HH:mm"
                    placeholder="课程开始时间"
                    style={{width: inputValueWidth}}
                  />
                )}
              </FormItem>
            </Col>

            <Col sm={24}>
              <Col sm={12}>
                <FormItem label="名字">
                  {getFieldDecorator('name', {
                    rules: [{required: true, message: '请输入课程名'}],
                  })(
                    <Input
                      prefix={<Icon type="team" style={{color: 'rgba(0,0,0,.25)'}}/>}
                      placeholder="课程名"
                      style={{width: inputValueWidth}}
                    />
                  )}
                </FormItem>
              </Col>

              <Col sm={12}>
                <FormItem label="教室">
                  {getFieldDecorator('classroom', {
                    rules: [{required: true, message: '请输入上课教室'}],
                  })(
                    <Select
                      showSearch
                      placeholder="选择教室"
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{width: inputValueWidth}}
                    >
                      {ClassroomStore.classroomOptions}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Col>

            <Col sm={24}>
              <Col sm={12}>
                <FormItem label="时长">
                  {getFieldDecorator('duration', {
                    rules: [{required: true, message: '请输入课程时长'}],
                  })(
                    <Input
                      prefix={<Icon type="team" style={{color: 'rgba(0,0,0,.25)'}}/>}
                      placeholder="课程时长"
                      style={{width: inputValueWidth}}
                    />
                  )}
                </FormItem>
              </Col>

              <Col sm={12}>
                <FormItem label="教师">
                  {getFieldDecorator('teacher', {
                    rules: [{required: true, message: '请输入任课教师'}],
                  })(
                    <Select
                      showSearch
                      placeholder="选择教师"
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{width: inputValueWidth}}
                    >
                      {TeacherStore.teacherOptions}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Col>

            <Col sm={24}>
              <Col sm={12}>
                <FormItem label="频率">
                  {getFieldDecorator('frequency', {
                    defaultValue: '每周',
                    rules: [{required: true, message: '请输入课程频率'}],
                  })(
                    <Select style={{width: inputValueWidth}}>
                      <Select.Option key={1} value="per_week">每周</Select.Option>
                      <Select.Option key={2} value="two_week">每两周</Select.Option>
                      <Select.Option key={3} value="per_month">每个月</Select.Option>
                    </Select>
                  )}
                </FormItem>
              </Col>

              <Col sm={12}>
                <FormItem label="次数">
                  {getFieldDecorator('times', {
                    rules: [{required: true, message: '请输入上课次数'}],
                  })(
                    <Input prefix={<Icon type="team" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="上课次数"
                           style={{width: inputValueWidth}}/>
                  )}
                </FormItem>
              </Col>

              <Col sm={12}>
                <FormItem label="类型">
                  {getFieldDecorator('type', {
                    initialValue: 'NULL',
                  })(
                    <Select style={{width: inputValueWidth}}>
                      {Object.keys(ClassRecordChoices).map(
                        item => <Select.Option key={item}>{
                          ClassRecordChoiceNames[item]
                        }</Select.Option>
                      )}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Col>

            <Col span={4} push={20}>
              <br/>
              <Button
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError())}
              >
                增加
              </Button>
            </Col>
            <Divider orientation="left">已有安排</Divider>
            <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={ClassRecordBatchCreateStore.classRecordsToCreate}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={this.getItemTitle(item)}
                      description={this.getItemDescription(item)}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
}

const WrappedClassRecordBatchCreateStore = Form.create()(ClassRecordBatchCreateModal);

export default WrappedClassRecordBatchCreateStore;
