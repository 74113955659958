import {action, observable} from 'mobx';
import DataSet from "@antv/data-set";

import FetchProvider from "../../../shared/RequestProvider";
import {arraySum} from "../../../shared/utils";

class DetailedClassroomUsageStore {
  @observable loading = false;
  @observable detailedClassroomUsage = [];
  @observable resolvedData = {};
  @observable dataToShow = [];

  @action fetchDetailedClassroomUsage = () => {
    this.loading = true;
    return FetchProvider.get('/api/dashboard/detailed_classroom_usage/')
      .then((res) => {
        this.detailedClassroomUsage = res.data;

        // Combine records.
        this.resolvedData = {};
        for (const item of this.detailedClassroomUsage) {
          const {instance, rates} = item;
          const {location, full_name, location_name} = instance;
          if (!this.resolvedData[location])
            this.resolvedData[location] = {
              unresolved: {},
              data: [],
              table: [],
            };
          // Dates
          this.resolvedData[location]['name'] = location_name;
          for (const date in rates) {
            if (date && !this.resolvedData[location]['unresolved'][date])
              this.resolvedData[location]['unresolved'][date] = {};
            this.resolvedData[location]['unresolved'][date][full_name] = rates[date];
          }
          // Table
          const rate_vals = Object.values(rates);
          this.resolvedData[location].table.push({
            name: full_name,
            today: rate_vals[0] / 600,
            week: arraySum(rate_vals.slice(0, 7)) / (600 * 7),
            month: arraySum(rate_vals) / (600 * 31),
          })
        }

        // Use data-view to merge them.
        this.dataToShow = [];
        for (const location in this.resolvedData) {
          let fields = [];
          for (const date in this.resolvedData[location]['unresolved']) {
            const tmp = this.resolvedData[location]['unresolved'][date];
            if (fields.length === 0)
              fields = Object.keys(tmp);
            tmp['date'] = date;
            this.resolvedData[location]['data'].push(tmp);
          }
          const ds = new DataSet();
          const dv = ds.createView().source(this.resolvedData[location]['data']);
          dv.transform({
            type: "fold",
            fields,
            key: "classroom",
            value: "usage",
          });
          this.dataToShow.push({
            name: this.resolvedData[location].name,
            data: dv,
            table: this.resolvedData[location].table,
          })
        }
        this.loading = false;
      });
  };
}

export default new DetailedClassroomUsageStore();
