import React from 'react';
import {Link} from 'react-router-dom';
import {Table, Card, Tabs} from "antd";
import {observer} from "mobx-react";

import CourseCalendar from "../../class/components/CourseCalendar";
import StudentDetailStore from "../stores/StudentDetailStore";
import GenericClassRecordList from "../../class/components/GenericClassRecordList";


const courseColumns = [{
  title: '课程 ID', key: 'cid',
  render: (item) => <Link
    to={`/class/${item.class_id}`}>{item.class_id}</Link>
}, {
  title: '课程名',
  dataIndex: 'name',
}, {
  title: '上课地点',
  dataIndex: 'location_name',
}];

@observer
class StudentDetail extends React.Component {
  componentWillMount = () => {
    const {studentId} = this.props;
    StudentDetailStore.fetchStudentCourses(studentId);
  };

  render() {
    return (
      <div>
        <Card
          size="large"
          title="正在参与的课程"
          style={{width: '100%'}}
          className="student-detail-card"
        >
          <Table
            rowKey={item => item.class_id}
            columns={courseColumns}
            dataSource={StudentDetailStore.studentCoursesDetail}
            loading={StudentDetailStore.loading}
            pagination={false}
          />
        </Card>
        <Card
          size="large"
          title="上课安排"
          style={{width: '100%'}}
          className="student-detail-card"
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="日历" key="1">
              <CourseCalendar
                showInterval
                dailyCourseList={StudentDetailStore.studentCourseRecordsDetail.concat(
                  StudentDetailStore.studentFinishedCourseRecordsDetail)}
                loading={StudentDetailStore.loading}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="未上课时" key="2">
              <GenericClassRecordList
                courseRecords={StudentDetailStore.studentCourseRecordsDetail}
                loading={StudentDetailStore.loading}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="已上课时" key="3">
              <GenericClassRecordList
                courseRecords={StudentDetailStore.studentFinishedCourseRecordsDetail}
                loading={StudentDetailStore.loading}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
}

export default StudentDetail;
