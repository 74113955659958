import React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';

import {Table, Button, Select} from 'antd';
import StudentStore from "../stores/StudentStore";
import ColumnSearchProps from "../../../shared/ColumnSearchProps";


@observer
class StudentList extends React.Component {
  componentWillMount = () => {
    StudentStore.fetchStudentList();
  };

  getColumnSearchProps = (dataIndex) => {
    return ColumnSearchProps.getColumnSearchProps(
      dataIndex, this.handleSearch, this.handleReset, StudentStore.searchText);
  };

  handleSearch = (dataIndex, selectedKeys, confirm) => {
    confirm();
    StudentStore.searchText = selectedKeys[0];
    StudentStore.searchStudents(dataIndex, selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    StudentStore.searchText = '';
    StudentStore.fetchStudentList();
  };

  handleTableChange = (pagination, filters, sorter) => {
    if(!StudentStore.searchText){
      StudentStore.fetchStudentList(pagination.current);
    }
  };

  render() {
    const columns = [{
      title: '学生 ID',
      dataIndex: 'student_id',
      ...this.getColumnSearchProps('student_id'),
    }, {
      title: '姓名',
      dataIndex: 'name',
      ...this.getColumnSearchProps('name'),
    // }, {
    //   title: '英文名',
    //   dataIndex: 'eng_name',
    //   ...this.getColumnSearchProps('eng_name'),
    }, {
      title: '电话',
      dataIndex: 'phone',
    }, {
      title: '操作', dataIndex: '', key: 'x',
      render: (item) => <div>
        <Link to={`/student/${item.student_id}`}><Button type="primary">详情</Button></Link>&nbsp;&nbsp;
        <Select placeholder="设置状态" style={{ width: 120 }} onChange={value => {
          StudentStore.setStudentStatus(item.student_id, value.toString())
            .then(() => {
              StudentStore.fetchStudentList(StudentStore.pagination.current);
            });
        }}>
          <Select.Option value="1">活跃</Select.Option>
          <Select.Option value="0">毕业</Select.Option>
          <Select.Option value="-1">异常</Select.Option>
        </Select>
      </div>
    }];
    return (
      <Table
        rowKey={item => item.student_id}
        columns={columns}
        dataSource={StudentStore.studentList}
        pagination={StudentStore.pagination}
        onChange={this.handleTableChange}
        loading={StudentStore.loading}
      />
    )
  }
}

export default StudentList;
