import {action, observable} from "mobx";
import FetchProvider from "../../../shared/RequestProvider";


class StudentOverviewStore{
  @observable loading = false;
  @observable studentOverview = {
    course_statistics: {
      past: 0,
      past_time: 0,
      future: 0,
      future_time: 0,
      current: '',
      next: '',
      types: [{
        label: '',
        past: 0,
        future: 0,
      }],
    }
  };

  @action fetchStudentOverview = (studentId) => {
    this.loading = true;
    return FetchProvider.get(`/api/student/${studentId}/overview/`)
      .then((res) => {
        this.studentOverview = res.data;
        this.loading = false;
      })
  };
}

export default new StudentOverviewStore();
