import React from 'react';
import {observer} from "mobx-react";
import { Row, Col, Tabs } from 'antd';

import WrappedTeacherEditor from "../components/TeacherEditor";
import TeacherCourses from "../components/TeacherCourses";
import TeacherOverview from "../components/TeacherOverview";
import TeacherStore from "../stores/TeacherStore";

import '../styles.css';


@observer
class TeacherEditorContainer extends React.Component{
  componentWillMount() {
    const {teacherId} = this.props.match.params;
    TeacherStore.fetchTeacher(teacherId);
  }

  render(){
    return (
      <Row>
        <Col span={24}>
          <h2>{TeacherStore.teacherDetail.name}</h2>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="总览" key="1">
              <TeacherOverview teacherId={this.props.match.params.teacherId}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="课程" key="2">
              <TeacherCourses teacherId={this.props.match.params.teacherId}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="编辑" key="3">
              <WrappedTeacherEditor teacherId={this.props.match.params.teacherId}/>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>

    )
  }
}

export default TeacherEditorContainer;
