import React from 'react';
import {observer} from "mobx-react";
import {Button, Row, Col, Select, message, Divider} from "antd";

import StudentSelectStore from "../stores/StudentSelectStore";
import StudentSelectList from "./StudentSelectList";


@observer
class ClassStudentSelectList extends React.Component {
  componentWillMount() {
    this.reloadStudentList();
  }

  reloadStudentList = () => {
    StudentSelectStore.fetchStudentsByClassId(this.props.classId)
      .then(() => {
        let studentsWithAction = [];
        for (let item of StudentSelectStore.students){
          item['action'] = [
            <Button key={item.student_id} type="danger" onClick={() => {
              StudentSelectStore.removeStudentFromClass(
                item.student_id, this.props.classId)
                .then(this.reloadStudentList)
            }}>删除</Button>
          ];
          studentsWithAction.push(item);
        }
        StudentSelectStore.students = studentsWithAction;
      });
  };

  searchStudent = (value) => {
    StudentSelectStore.searchStudent(value);
  };

  addStudent = () => {
    const studentId = StudentSelectStore.selectedStudent;
    if (studentId === -1){
      message.warn('未选择学生');
    }
    else{
      StudentSelectStore.addStudentToClass(studentId, this.props.classId)
        .then(() => {
          this.reloadStudentList();
        })
    }
  };

  render() {
    return (
      <div>
        <Row>
          <h2>上课学生</h2>
          <Divider orientation="left">选择学生</Divider>
          <Col span={18}>
            <Select
              showSearch
              placeholder="请输入学生学号或姓名"
              filterOption={false}
              onSearch={this.searchStudent}
              onChange={(value) => {StudentSelectStore.selectedStudent = value}}
              style={{ width: '100%' }}
            >
              {StudentSelectStore.searchResult.slice().map(
                item => <Select.Option
                  key={item.student_id}
                  value={item.student_id}
                >（{item.student_id}）{item.name}</Select.Option>)}
            </Select>
          </Col>
          <Col offset={2} span={4}>
            <Button type="primary" onClick={this.addStudent}>
              添加
            </Button>
          </Col>

          <Divider orientation="left" style={{paddingTop: 30}}>学生列表</Divider>
          <Col span={24}>
            <StudentSelectList
              loading={StudentSelectStore.loading}
              dataSource={StudentSelectStore.students}
            />
          </Col>
        </Row>

      </div>
    );
  }
}

export default ClassStudentSelectList;
