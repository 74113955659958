import {observable, action} from 'mobx';
import FetchProvider from '../../../shared/RequestProvider';


class TeacherOverviewStore {
  @observable loading = false;
  @observable teacherOverview = {
    monthly_course_stat: [],
  };

  @action fetchTeacherOverview = (teacherId) => {
    this.loading = true;
    return FetchProvider.get(`/api/teacher/${teacherId}/overview/`)
      .then((res) => {
        this.loading = false;
        this.teacherOverview = res.data;
      });
  }
}

export default new TeacherOverviewStore();
