import React from 'react';
import { action, observable } from 'mobx';
import FetchProvider from "../../../shared/RequestProvider";
import {Select} from "antd";

class TeacherStore{
  @observable loading = false;
  @observable pagination = {
    total: 0,
    current: 1,
    next: null,
    previous: null,
    pageSize: 1,
  };
  @observable teacherList = [];
  @action fetchTeacherList = (page=1) => {
    this.loading = true;
    return FetchProvider.get(`/api/teacher/?page=${page}`)
      .then((res) => {
        if(res.data){
          const data = res.data;
          this.teacherList = data.results;
          this.pagination = {
            total: data.count,
            current: page,
            next: data.next,
            previous: data.previous,
            pageSize: 10
          };
          this.loading = false;
        }
      });
  };
  @action getTeacherNameById = (teacherId) => {
    teacherId = parseInt(teacherId);
    for(let item of this.simpleTeacherList){
      if (item.teacher_id === teacherId) {
        return item.name;
      }
    }
    return '';
  };

  @observable searchText = '';
  @action searchTeachers = (dataIndex, searchText) => {
    this.loading = true;
    return FetchProvider.get(`/api/teacher/?${dataIndex}=${searchText}`)
      .then((res) => {
        if(res.data){
          const data = res.data;
          this.teacherList = data.results;
          this.pagination = {
            total: data.count,
            current: 1,
            next: data.next,
            previous: data.previous,
            pageSize: 10
          };
          this.loading = false;
        }
      });
  };

  @observable teacherDetail = {};
  @action fetchTeacher = (teacherId) => {
    this.loading = true;
    return FetchProvider.get(`/api/teacher/${teacherId}/`)
      .then((res) => {
        if(res.data){
          this.teacherDetail = res.data;
          this.loading = false;
        }
      });
  };
  @action updateTeacher = (teacher_id, update_data) => {
    this.loading = true;
    return FetchProvider.patch(`/api/teacher/${teacher_id}/`, update_data)
      .then((res) => {
        if(res.data) {
          this.teacherDetail = res.data;
          this.loading = false;
        }
      });
  };
  @action deleteTeacher = (teacher_id) => {
    return FetchProvider.delete(`/api/teacher/${teacher_id}/`);
  };

  @observable simpleTeacherList = [];
  @observable teacherOptions = [];
  @action fetchTeacherOptions = () => {
    return FetchProvider.get(`/api/teacher/all/`)
      .then((res) => {
        if(res.data) {
          this.simpleTeacherList = res.data;

          this.teacherOptions = [];
          for(let item of this.simpleTeacherList){
            this.teacherOptions.push(
              <Select.Option
                alt={item.name}
                key={item.teacher_id}
                value={item.teacher_id}
              >{item.name}</Select.Option>
            )
          }
          this.teacherOptions.push(
            <Select.Option key={-1} value={null}>无</Select.Option>
          )
        }
      });
  };

  // Confirm teacher delete
  @observable confirmDeleteTeacherVisible = false;
  @observable teacherToDelete = {};
}

export default new TeacherStore();
