import React from 'react';
import {Link} from 'react-router-dom';
import { observer } from 'mobx-react';

import {Table, Button} from 'antd';
import ClassStore from "../stores/ClassStore";

const columns = [{
  title: '班级编号',
  dataIndex: 'class_no',
}, {
  title: '课程名字',
  dataIndex: 'name',
}, {
  title: '预计人数',
  dataIndex: 'capacity',
}, {
  title: '任课老师',
  dataIndex: 'teacher_name',
}, {
  title: '校区',
  dataIndex: 'location_name',
}, {
  title: '操作', dataIndex: '', key: 'x',
  render: (item) => <Link to={`/class/${item.class_id}`}><Button type="primary">详情</Button></Link>
}];

@observer
class ClassList extends React.Component{
  componentWillMount() {
    ClassStore.fetchClassList();
  }

  handleTableChange = (pagination, filters, sorter) => {
    ClassStore.fetchClassList(pagination.current);
  };

  render(){
    return(
      <Table
        rowKey={item => item.class_id}
        columns={columns}
        dataSource={ClassStore.classList}
        pagination={ClassStore.pagination}
        onChange={this.handleTableChange}
        loading={ClassStore.loading}
      />
    )
  }
}

export default ClassList;
