import React from 'react';
import {Route, Switch} from 'react-router-dom';
import TeacherListContainer from "./containers/TeacherListContainer";
import TeacherEditorContainer from "./containers/TeacherEditorContainer";

class TeacherIndex extends React.Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route exact path={`${match.url}/:teacherId`} component={TeacherEditorContainer}/>
        <Route exact path={`${match.url}`} component={TeacherListContainer}/>
      </Switch>
    );
  }
}

export default TeacherIndex;
