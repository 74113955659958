import React from 'react';
import {observer} from "mobx-react";
import {Card, Modal, Tabs} from "antd";
import {
  Chart,
  Geom,
  Axis,
  Tooltip,
} from "bizcharts";

import CourseCalendar from "../../class/components/CourseCalendar";
import ClassroomDetailStore from "../stores/ClassroomDetailStore";
import GenericClassRecordList from "../../class/components/GenericClassRecordList";
import {intToHourMinute} from '../../../shared/utils';

@observer
class ClassroomDetailModal extends React.Component {
  render() {
    const scale = {
      occupation: {
        type: 'linear',
        min: 480,
        max: 1320,
        tickInterval: 60,
      },
    };

    return (
      <Modal
        title="教室排课详情"
        visible={ClassroomDetailStore.modalVisible}
        onCancel={() => {
          ClassroomDetailStore.modalVisible = false
        }}
        width={1000}
        footer={false}
        destroyOnClose
      >
        <Card
          size="large"
          title="教室占用情况"
          loading={ClassroomDetailStore.loading}
          style={{width: '100%'}}
          className="location-card"
        >
          <Chart
            scale={scale}
            height={400}
            data={ClassroomDetailStore.classroomHeatMap}
            forceFit>
            <Axis name="day"/>
            <Axis
              name="occupation"
              label={{
                formatter: val => intToHourMinute(val)
              }}
            />
            <Tooltip
              useHtml
              htmlContent={(title, items) => {
                let listDom = '';
                for (const item of items) {
                  if (parseInt(item.value) === 0) {
                    continue;
                  }
                  listDom += '';
                  const itemDom = `<li data-index={index}><span style="background-color:${item.color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>${item.point._origin.course}<br/>&nbsp;&nbsp;<span class="g2-tooltip-value">${intToHourMinute(item.value.split('-')[0])} - ${intToHourMinute(item.value.split('-')[1])}</span></li>`;
                  listDom += itemDom;
                }
                return '<div class="custom-tooltip">'
                  + `<div class="custom-tooltip-title" style="margin-bottom: 4px;">${title}</div>`
                  + `<ul class="custom-tooltip-list">${listDom}</ul>`
                  + '</div>'
              }}
            />
            <Geom
              type="interval"
              position="day*occupation"
              size={25}
              opacity={0.7}
            />
          </Chart>
        </Card>
        <Card
          size="large"
          title="上课安排"
          loading={ClassroomDetailStore.loading}
          className="location-card"
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="日历" key="1">
              <CourseCalendar
                dailyCourseList={ClassroomDetailStore.classroomCourseRecordsDetail.concat(
                  ClassroomDetailStore.classroomFinishedCourseRecordsDetail)}
                loading={ClassroomDetailStore.loading}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="未上课程" key="2">
              <GenericClassRecordList
                courseRecords={ClassroomDetailStore.classroomCourseRecordsDetail}
                loading={ClassroomDetailStore.loading}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="已上课程" key="3">
              <GenericClassRecordList
                courseRecords={ClassroomDetailStore.classroomFinishedCourseRecordsDetail}
                loading={ClassroomDetailStore.loading}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Modal>
    );
  }
}

export default ClassroomDetailModal;
