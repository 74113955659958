import React from 'react';
import {observer} from 'mobx-react';

import {
  Form, Icon, Input, Button, Row, Col, Card, message
} from 'antd';
import StudentStore from "../stores/StudentStore";

const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

@observer
class StudentEditor extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        StudentStore.updateStudent(this.props.studentId, values)
          .then(() => {
            message.success('Update success!');
          })
          .else((res) => {
            message.error(`Update failed! Msg: ${res.response.toString()}`);
          });
      }
    });
  };

  render() {
    const {
      getFieldDecorator, getFieldsError,
    } = this.props.form;

    // Only show error after a field is touched.
    return (

      <Form
        layout="vertical"
        onSubmit={this.handleSubmit}>
        <Card
          title="Student detail"
          loading={StudentStore.loading}
          extra={<Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError()) || StudentStore.loading}
          >
            Save
          </Button>}
        >
          <Row gutter={{sm: 16, md: 24}}>
            <Col sm={24} md={12}>
              <FormItem label="Student ID">
                {getFieldDecorator('student_id', {
                  initialValue: StudentStore.studentDetail.student_id,
                  rules: [{required: true, message: ''}],
                })(
                  <Input prefix={<Icon type="idcard" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Username"/>
                )}
              </FormItem>

              <FormItem label="Name">
                {getFieldDecorator('name', {
                  initialValue: StudentStore.studentDetail.name,
                  rules: [{required: true, message: 'Please input student\'s name!'}],
                })(
                  <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Name"/>
                )}
              </FormItem>

              <FormItem label="English name">
                {getFieldDecorator('eng_name', {
                  initialValue: StudentStore.studentDetail.eng_name,
                })(
                  <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="English name"/>
                )}
              </FormItem>

              <FormItem label="Email">
                {getFieldDecorator('email', {
                  initialValue: StudentStore.studentDetail.email,
                })(
                  <Input prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Email"/>
                )}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>

              <FormItem label="Phone">
                {getFieldDecorator('phone', {
                  initialValue: StudentStore.studentDetail.phone,
                })(
                  <Input prefix={<Icon type="phone" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Phone"/>
                )}
              </FormItem>

              <FormItem label="ID">
                {getFieldDecorator('identification', {
                  initialValue: StudentStore.studentDetail.identification,
                })(
                  <Input prefix={<Icon type="idcard" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="ID"/>
                )}
              </FormItem>

              <FormItem label="Register date">
                {getFieldDecorator('register_date', {
                  initialValue: StudentStore.studentDetail.register_date,
                  rules: [{required: true, message: ''}],
                })(
                  <Input prefix={<Icon type="code" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Register date"
                         disabled={true}/>
                )}
              </FormItem>

              <FormItem label="付费课程时长(小时)">
                {getFieldDecorator('paid_course', {
                  initialValue: StudentStore.studentDetail.paid_course,
                  rules: [{required: true, message: '请输入付费课程时长'}],
                })(
                  <Input type="number" prefix={<Icon type="dollar" style={{color: 'rgba(0,0,0,.25)'}}/>}
                         placeholder="付费课程时长"/>
                )}
              </FormItem>
            </Col>

            <Col sm={24}>
              <FormItem label="备注">
                {getFieldDecorator('comment', {
                  initialValue: StudentStore.studentDetail.comment,
                  rules: [{message: '请输入学生备注'}],
                })(
                  <Input.TextArea placeholder="备注"/>
                )}
              </FormItem>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

const WrappedStudentEditor = Form.create()(StudentEditor);

export default WrappedStudentEditor;
