import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ClassListContainer from "./containers/ClassListContainer";
import ClassEditorContainer from "./containers/ClassEditorContainer";

class ClassIndex extends React.Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route exact path={`${match.url}/:classId`} component={ClassEditorContainer}/>
        <Route path={`${match.url}`} component={ClassListContainer}/>
      </Switch>
    );
  }
}

export default ClassIndex;
