import React from 'react';
import {observer} from 'mobx-react';
import {List, Button, Row, Col, Divider} from 'antd';
import Moment from 'moment';

import ClassRecordStore from "../stores/ClassRecordStore";

import {translateWeekName} from "../../../shared/utils";
import ClassroomStore from "../../campus/stores/ClassroomStore";
import ClassStore from "../stores/ClassStore";
import ClassRecordBatchCreateStore from "../stores/ClassRecordBatchCreateStore";
import ClassSignInStore from "../stores/ClassSignInStore";

import '../styles/ClassRecordList.css';
import {ClassRecordChoiceNames} from "../constants";
import ClassRecordFeedbackStore from '../stores/ClassRecordFeedbackStore';


@observer
class ClassRecordList extends React.Component {
  componentWillMount = () => {
    const {classId} = this.props;
    ClassRecordStore.fetchClassRecordList(classId);
  };

  showClassRecordCreateModal = () => {
    ClassRecordStore.isCreate = true;
    ClassRecordStore.isCopy = false;
    ClassRecordBatchCreateStore.allowSave = false;
    ClassRecordStore.showClassRecordEditModal = true;
    ClassroomStore.fetchClassroomOptions(ClassStore.classDetail.location);
  };

  showClassRecordEditModal = (classrecord_id) => {
    ClassRecordStore.isCreate = false;
    ClassRecordStore.isCopy = false;
    ClassRecordStore.showClassRecordEditModal = true;
    ClassRecordStore.fetchClassRecord(classrecord_id);
    ClassroomStore.fetchClassroomOptions(ClassStore.classDetail.location);
  };

  showClassRecordCopyModal =  (classrecord_id) => {
    ClassRecordStore.isCreate = false;
    ClassRecordStore.isCopy = true;
    ClassRecordStore.showClassRecordEditModal = true;
    ClassRecordStore.fetchClassRecord(classrecord_id);
    ClassroomStore.fetchClassroomOptions(ClassStore.classDetail.location);
  };

  deleteClassRecord = (classrecord_id) => {
    ClassRecordStore.deleteClassRecord(classrecord_id)
      .then(() => {
        ClassRecordStore.fetchClassRecordList(this.props.classId);
      })
  };

  getClassRecordTitle = (item) => {
    const time = Moment(item.start_at);
    const now = Moment.now();
    const start_at = Moment(item.start_at);
    const end_at = Moment(item.end_at);

    return <div>
      {(start_at > now) && <div className="course-waiting">未开始</div>}
      {(end_at < now) && <div className="course-finished">结束</div>}
      {(start_at < now) && (end_at > now) && <div className="course-ongoing">正在上课</div>}
      <h3>{item.name} （{ClassRecordChoiceNames[item.type]}）</h3>
      第{item.order}次 {time.format('YYYY-MM-DD')} {translateWeekName(time.format('ddd'))}
    </div>
  };

  getClassRecordDescription = (item) => {
    return (<div>
      <p>开始于 {Moment(item.start_at).format('HH:mm')}，持续 {item.duration} 分钟</p>
      <p>反馈：{item.feedback}</p>
    </div>)
  };

  showClassSignInModal = (class_record_id) => {
    ClassSignInStore.targetClassRecord = class_record_id;
    ClassSignInStore.fetchStudents(class_record_id)
      .then(() => {
        ClassSignInStore.visible = true;
      });
  };

  render() {
    return (
      <Row>
        <Col span={16}>
          <h2>课时安排</h2>
        </Col>
        <Col span={8} className='header-buttons'>
          <Button onClick={() => {
            ClassRecordBatchCreateStore.showModal = true;
            ClassRecordBatchCreateStore.allowSave = false;
            ClassroomStore.fetchClassroomOptions(ClassStore.classDetail.location);
          }}>批量添加</Button>
          &nbsp;&nbsp;
          <Button type="primary" onClick={this.showClassRecordCreateModal}>添加</Button>
        </Col>
        <Divider/>
        <Col span={24}>
          <List
            itemLayout="horizontal"
            dataSource={ClassRecordStore.classRecordList}
            renderItem={item => (
              <List.Item actions={[
                <Button type="primary" onClick={() => {
                  this.showClassSignInModal(item.id)
                }}>
                  签到
                </Button>,
                <Button onClick={() => {
                  this.showClassRecordCopyModal(item.id)
                }}>
                  复制
                </Button>,
                <Button onClick={() => {
                  this.showClassRecordEditModal(item.id)
                }}>
                  修改
                </Button>,
                <Button type="danger" onClick={() => {
                  this.deleteClassRecord(item.id)
                }}>
                  删除
                </Button>,
                <Button type="primary" onClick={() => {
                  ClassRecordFeedbackStore.crId = item.id;
                  ClassRecordFeedbackStore.modalVisible = true;
                  ClassRecordFeedbackStore.fetchClassRecordFeedback();
                }}>
                  反馈
                </Button>,
              ]}>
                <List.Item.Meta
                  title={this.getClassRecordTitle(item)}
                  description={this.getClassRecordDescription(item)}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    )
  }
}

export default ClassRecordList
