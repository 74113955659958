import { action, observable } from 'mobx';
import FetchProvider from "../../../shared/RequestProvider";

class ClassStore{
  @observable teacherOptions = [];
  @observable loading = false;
  @observable pagination = {
    total: 0,
    current: 1,
    next: null,
    previous: null,
    pageSize: 1,
  };

  @observable searchClassType = '';

  @observable classList = [];
  @action fetchClassList = (page=1) => {
    this.loading = true;
    const params = {
      page,
      type: this.searchClassType,
    };
    return FetchProvider.get('/api/class/', {params})
      .then((res) => {
        if(res.data){
          const data = res.data;
          this.classList = data.results;
          this.pagination = {
            total: data.count,
            current: page,
            next: data.next,
            previous: data.previous,
            pageSize: 10
          };
          this.loading = false;
        }
      });
  };

  @observable classDetail = {};
  @action fetchClass = (class_id) => {
    this.loading = true;
    return FetchProvider.get(`/api/class/${class_id}/`)
      .then((res) => {
        if(res.data){
          this.classDetail = res.data;
          this.loading = false;
        }
      });
  };
  @action updateClass = (class_id, update_data) => {
    this.loading = true;
    return FetchProvider.patch(`/api/class/${class_id}/`, update_data)
      .then((res) => {
        if(res.data) {
          this.classDetail = res.data;
          this.loading = false;
        }
      });
  };
}

export default new ClassStore();
