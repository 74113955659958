import React from 'react';
import {Route, Switch} from 'react-router-dom';
import LocationListContainer from "./containers/LocationListContainer";
import LocationEditorContainer from "./containers/LocationEditorContainer";

class CampusIndex extends React.Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route exact path={`${match.url}/:locationId`} component={LocationEditorContainer}/>
        <Route path={`${match.url}`} component={LocationListContainer}/>
      </Switch>
    );
  }
}

export default CampusIndex;
