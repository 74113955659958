import React from 'react';
import {Route, Switch} from 'react-router-dom';
import StudentListContainer from "./containers/StudentListContainer";
import StudentEditorContainer from "./containers/StudentEditorContainer";

class StudentIndex extends React.Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route exact path={`${match.url}/:studentId`} component={StudentEditorContainer}/>
        <Route path={`${match.url}`} component={StudentListContainer}/>
      </Switch>
    );
  }
}

export default StudentIndex;
