import React from 'react';
import {observer} from 'mobx-react';

import {
  Modal, Button, Form, Row, Col, message, Input, Icon
} from 'antd';
import ClassroomStore from "../stores/ClassroomStore";
import LocationStore from "../stores/LocationStore";

const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

@observer
class ClassroomModal extends React.Component {
  handleCancel = () => {
    ClassroomStore.showClassroomEditModal = false;
  };
  handleOk = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (ClassroomStore.isCreate) {
          values.location = LocationStore.locationDetail.id;
          ClassroomStore.createClassroom(values)
            .then(() => {
              message.success('创建成功！');
              ClassroomStore.fetchClassroomList(ClassroomStore.classroomDetail.location);
              ClassroomStore.showClassroomEditModal = false;
            })
            .else((res) => {
              message.error(`创建失败！ Msg: ${res.response.toString()}`);
            });
        } else {
          ClassroomStore.updateClassroom(ClassroomStore.classroomDetail.id, values)
            .then(() => {
              message.success('更新成功！');
              ClassroomStore.fetchClassroomList(ClassroomStore.classroomDetail.location);
              ClassroomStore.showClassroomEditModal = false;
            })
            .else((res) => {
              message.error(`更新失败！ Msg: ${res.response.toString()}`);
            });
        }
      }
    });
  };

  render() {
    const isCreate = ClassroomStore.isCreate;

    const {
      getFieldDecorator, getFieldsError,
    } = this.props.form;

    return (
      <Modal
        visible={ClassroomStore.showClassroomEditModal}
        title="Title"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="location-card"
        footer={[
          <Button key="取消" onClick={this.handleCancel}>取消</Button>,
          <Button key="提交" type="primary" loading={ClassroomStore.loadingModal}
                  onClick={this.handleOk} disabled={hasErrors(getFieldsError())}>
            保存
          </Button>,
        ]}
        destroyOnClose={true}
      >
        <Form
          layout="vertical"
          onSubmit={this.handleOk}>
          <Row>
            <Col sm={24}>
              <FormItem label="教室名">
                {getFieldDecorator('name', {
                  initialValue: isCreate ? '' : ClassroomStore.classroomDetail.name,
                  rules: [{required: true, message: '请输入教室名字'}],
                })(
                  <Input prefix={<Icon type="book" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="教室名"/>
                )}
              </FormItem>

              <FormItem label="容纳人数">
                {getFieldDecorator('capacity', {
                  initialValue: isCreate ? '' : ClassroomStore.classroomDetail.capacity,
                  rules: [{required: true, message: '请输入容纳人数'}],
                })(
                  <Input prefix={<Icon type="team" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Capacity"/>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
}

const WrappedClassroomModal = Form.create()(ClassroomModal);

export default WrappedClassroomModal;
