import {action, observable} from 'mobx';

import FetchProvider from "../../../shared/RequestProvider";

class TeacherDetailStore {
  @observable loading = true;

  @observable teacherDetail = {};
  @observable teacherCoursesDetail = [];
  @observable teacherCourseRecordsDetail = [];
  @observable teacherFinishedCourseRecordsDetail = [];

  @action fetchTeacher = (teacherId) => {
    return FetchProvider.get(`/api/teacher/${teacherId}/`)
      .then((res) => {
        if (res.data) {
          this.teacherDetail = res.data;
        }
      });
  };

  @action fetchTeacherCourses = (teacherId) => {
    this.loading = true;
    return FetchProvider.get(`/api/teacher/${teacherId}/course/`)
      .then((res) => {
        if (res.data) {
          const data = res.data;
          this.teacherCoursesDetail = data.course_records.courses;
          this.teacherCourseRecordsDetail = data.course_records.course_records;
          this.teacherFinishedCourseRecordsDetail = data.course_records.finished_course_records;
          this.loading = false;
        }
      });
  };
}

export default new TeacherDetailStore();
