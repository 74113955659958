import React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';

import {Table, Button, Modal} from 'antd';
import TeacherStore from "../stores/TeacherStore";
import ColumnSearchProps from "../../../shared/ColumnSearchProps";


@observer
class TeacherList extends React.Component {
  componentWillMount = () => {
    TeacherStore.fetchTeacherList();
  };

  getColumnSearchProps = (dataIndex) => {
    return ColumnSearchProps.getColumnSearchProps(
      dataIndex, this.handleSearch, this.handleReset, TeacherStore.searchText)
  };

  handleSearch = (dataIndex, selectedKeys, confirm) => {
    confirm();
    TeacherStore.searchText = selectedKeys[0];
    TeacherStore.searchTeachers(dataIndex, selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    TeacherStore.searchText = '';
    TeacherStore.fetchTeacherList();
  };

  handleTableChange = (pagination, filters, sorter) => {
    TeacherStore.fetchTeacherList(pagination.current);
  };

  render() {
    const columns = [{
      title: '教师 ID',
      dataIndex: 'teacher_id',
      ...this.getColumnSearchProps('teacher_id'),
    }, {
      title: '姓名',
      dataIndex: 'name',
      ...this.getColumnSearchProps('name'),
    }, {
      title: '英文名',
      dataIndex: 'eng_name',
      ...this.getColumnSearchProps('eng_name'),
    }, {
      title: '电话',
      dataIndex: 'phone',
    }, {
      title: '操作', dataIndex: '', key: 'x',
      render: (item) => <div>
        <Link to={`/teacher/${item.teacher_id}`}><Button type="primary">详情</Button></Link>&nbsp;&nbsp;
        <Button
          type="danger"
          onClick={() => {
            TeacherStore.confirmDeleteTeacherVisible = true;
            TeacherStore.teacherToDelete = item;
          }}>删除</Button>
      </div>
    }];

    return (
      <div>
        <Table
          rowKey={item => item.teacher_id}
          columns={columns}
          dataSource={TeacherStore.teacherList}
          pagination={TeacherStore.pagination}
          onChange={this.handleTableChange}
          loading={TeacherStore.loading}
        />

        <Modal
          title="删除确认"
          visible={TeacherStore.confirmDeleteTeacherVisible}
          onOk={() => {
            TeacherStore.deleteTeacher(TeacherStore.teacherToDelete.teacher_id)
              .then(() => {
                TeacherStore.fetchTeacherList(TeacherStore.pagination.current)
              });
            TeacherStore.confirmDeleteTeacherVisible = false;
          }}
          onCancel={() => {
            TeacherStore.confirmDeleteTeacherVisible = false;
          }}
        >
          <p>确定要删除老师 {TeacherStore.teacherToDelete.name} 吗？</p>
        </Modal>
      </div>
    )
  }
}

export default TeacherList;
