import React from 'react';
import {observer} from 'mobx-react';

import {
  Form, Icon, Input, Button, Row, Col, message, Select, Divider, Tooltip
} from 'antd';
import ClassStore from "../stores/ClassStore";
import TeacherStore from "../../teacher/stores/TeacherStore";
import LocationStore from "../../campus/stores/LocationStore";

const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

@observer
class ClassEditor extends React.Component {
  componentDidMount() {
    ClassStore.fetchClass(this.props.classId);
    TeacherStore.fetchTeacherOptions();
    LocationStore.fetchLocationOptions();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        ClassStore.updateClass(this.props.classId, values)
          .then(() => {
            message.success('更新成功');
          })
          .catch((res) => {
            message.error(`更新失败，错误信息 ${res.response.toString()}`);
          });
      }
    });
  };

  render() {
    const {
      getFieldDecorator, getFieldsError,
    } = this.props.form;

    return (
      <Form
        layout="vertical"
        onSubmit={this.handleSubmit}
      >

        <Row gutter={{sm: 16, md: 24}}>
          <Col span={14}>
            <h2>课程设置</h2>
          </Col>
          <Col span={10} className='header-buttons'>
            <Tooltip placement="rightTop" title={
              <span>
                <p>课程编号由五部分组成</p>
                <p>显示为[VIP][Type][A-Level][Year][No.]</p>
                <p>[VIP] = V or W</p>
                <p>[Type] = I or T or A</p>
                <p>[A-Level] = M or P or C or E or O</p>
                <p>[Year] = xx</p>
                <p>[No.] = xxx</p>
              </span>
            }>
              <Icon type="question-circle" />
            </Tooltip>
            <Divider type="vertical"/>
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError()) || ClassStore.loading}
            >
              保存
            </Button>
          </Col>
          <Divider/>
          <Col sm={24} md={12}>
            <FormItem label="课程编号">
              {getFieldDecorator('class_no', {
                initialValue: ClassStore.classDetail.class_no,
                rules: [{required: true, message: '请输入课程编号'}],
              })(
                <Input prefix={<Icon type="bars" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Class No."/>
              )}
            </FormItem>
            <FormItem label="课程名">
              {getFieldDecorator('name', {
                initialValue: ClassStore.classDetail.name,
                rules: [{required: true, message: '请输入课程名'}],
              })(
                <Input prefix={<Icon type="bank" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Class name"/>
              )}
            </FormItem>
            <FormItem label="预计人数">
              {getFieldDecorator('capacity', {
                initialValue: ClassStore.classDetail.capacity,
                rules: [{required: true, message: '请输入预计人数'}],
              })(
                <Input prefix={<Icon type="bank" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="预计人数"/>
              )}
            </FormItem>
          </Col>
          <Col sm={24} md={12}>
            <FormItem label="教师">
              {getFieldDecorator('teacher', {
                initialValue: ClassStore.classDetail.teacher,
              })(
                <Select
                  showSearch
                  placeholder="选择上课教师"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {TeacherStore.teacherOptions}
                </Select>
              )}
            </FormItem>

            <FormItem label="校区">
              {getFieldDecorator('location', {
                initialValue: ClassStore.classDetail.location,
              })(
                <Select
                  showSearch
                  placeholder="选择校区"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {LocationStore.locationOptions}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedClassEditor = Form.create()(ClassEditor);

export default WrappedClassEditor;
