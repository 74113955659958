import React from 'react';
import {Link} from 'react-router-dom';
import { observer } from 'mobx-react';

import {Table, Button} from 'antd';
import LocationStore from "../stores/LocationStore";

const columns = [{
  title: '校区名',
  dataIndex: 'name',
}, {
  title: '校区地址',
  dataIndex: 'address',
}, {
  title: '操作', dataIndex: '', key: 'x',
  render: (item) => <Link to={`/location/${item.id}`}><Button type="primary">详情</Button></Link>
}];

@observer
class LocationList extends React.Component{
  componentWillMount() {
    LocationStore.fetchLocationList();
  }

  handleTableChange = (pagination, filters, sorter) => {
    LocationStore.fetchLocationList(pagination.current);
  };

  render(){
    return(
      <Table
        rowKey={item => item.id}
        columns={columns}
        dataSource={LocationStore.locationList}
        pagination={LocationStore.pagination}
        onChange={this.handleTableChange}
        loading={LocationStore.loading}
      />
    )
  }
}

export default LocationList;
