import React from 'react';
import {observer} from 'mobx-react';

import {
  Form, Icon, Input, Button, Row, Col, Card, message
} from 'antd';
import LocationStore from "../stores/LocationStore";

const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

@observer
class LocationEditor extends React.Component {
  componentDidMount() {
    LocationStore.fetchLocation(this.props.locationId);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        LocationStore.updateLocation(this.props.locationId, values)
          .then(() => {
            message.success('Update success!');
          })
          .else((res) => {
            message.error(`Update failed! Msg: ${res.response.toString()}`);
          });
      }
    });
  };

  render() {
    const {
      getFieldDecorator, getFieldsError,
    } = this.props.form;

    const { course_info } = LocationStore.locationDetail;
    console.log(course_info)

    return (
      <div>
        <Card
          title="校区信息"
          loading={LocationStore.loading}
          className="location-card"
        >
          {
            course_info &&
            <Row className="duration-stat">
              <Col span={8}>
                <p>上月：{course_info.last_month_duration}小时</p>
              </Col>
              <Col span={8}>
                <p>本月：{course_info.this_month_duration}小时</p>
              </Col>
              <Col span={8}>
                <p>未来：{course_info.future_duration}小时</p>
              </Col>
            </Row>
          }
        </Card>
        <Form
          layout="vertical"
          onSubmit={this.handleSubmit}>
          <Card
            title="校区编辑"
            loading={LocationStore.loading}
            className="location-card"
            extra={<Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError()) || LocationStore.loading}
            >
              保存
            </Button>}
          >
            <Row gutter={{sm: 16, md: 24}}>
              <Col sm={24}>
                <FormItem label="校区名">
                  {getFieldDecorator('name', {
                    initialValue: LocationStore.locationDetail.name,
                    rules: [{required: true, message: '请输入校区名'}],
                  })(
                    <Input prefix={<Icon type="bars" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="校区名"/>
                  )}
                </FormItem>

                <FormItem label="校区地址">
                  {getFieldDecorator('address', {
                    initialValue: LocationStore.locationDetail.address,
                    rules: [{required: true, message: '请输入校区地址'}],
                  })(
                    <Input prefix={<Icon type="bank" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="校区地址"/>
                  )}
                </FormItem>
              </Col>
            </Row>
          </Card>
        </Form>
      </div>
    );
  }
}

const WrappedLocationEditor = Form.create()(LocationEditor);

export default WrappedLocationEditor;
