import React from 'react';
import {Card, List, Table} from "antd";
import {observer} from "mobx-react";
import {
  Chart,
  Geom,
  Axis,
  Tooltip,
  Legend,
} from "bizcharts";

import DetailedClassroomUsageStore from "../stores/DetailedClassroomUsageStore";

import '../styles/DetailedClassroomUsage.css';


@observer
class DetailedClassroomUsage extends React.Component {
  componentDidMount = () => {
    DetailedClassroomUsageStore.fetchDetailedClassroomUsage();
  };

  render() {
    const tableCols = [{
      title: '教室',
      dataIndex: 'name',
      key: 'name',
    }, {
      title: '今日使用率',
      dataIndex: '',
      key: 'daily',
      render: item => `${(item.today * 100).toFixed(2)}%`,
    }, {
      title: '本周使用率',
      dataIndex: '',
      key: 'weekly',
      render: item => `${(item.week * 100).toFixed(2)}%`,
    }, {
      title: '本月使用率',
      dataIndex: '',
      key: 'monthly',
      render: item => `${(item.month * 100).toFixed(2)}%`,
    }];

    const chartCols = {
      date: {
        range: [0, 1]
      }
    };

    return (
      <List
        loading={DetailedClassroomUsageStore.loading}
        header={<div>教室使用明细</div>}
        split={false}
        dataSource={DetailedClassroomUsageStore.dataToShow}
        renderItem={item => (
          <Card
            size="small"
            loading={DetailedClassroomUsageStore.loading}
            title={item.name}
            className="detailed-classroom-usage-graph"
          >
            <Chart height={400} data={item.data} scale={chartCols} forceFit>
              <Legend/>
              <Axis name="date"/>
              <Axis
                name="usage"
                label={{
                  formatter: val => `${val} 分钟`
                }}
              />
              <Tooltip
                crosshairs={{
                  type: "y"
                }}
                itemTpl={'<li data-index={index}>'
                + '<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>'
                + '{name}: {value} 分钟'
                + '</li>'}
              />
              <Geom
                type="line"
                position="date*usage"
                size={2}
                color={"classroom"}
              />
              <Geom
                type="point"
                position="date*usage"
                size={4}
                shape={"circle"}
                color={"city"}
                style={{
                  stroke: "#fff",
                  lineWidth: 1
                }}
              />
            </Chart>
            <Table rowKey="name" dataSource={item.table} columns={tableCols} pagination={false} />
          </Card>
        )}
      />
    );
  }
}

export default DetailedClassroomUsage;
