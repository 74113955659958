import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Divider, Table} from "antd";
import {observer} from "mobx-react";

import Moment from 'moment';
import ClassSignInStore from "../stores/ClassSignInStore";
import ClassSignInModal from "./ClassSignInModal";
import ClassRecordFeedbackStore from '../stores/ClassRecordFeedbackStore';
import ClassRecordFeedbackModal from './ClassRecordFeedbackModal';


const courseRecordColumns = [{
  title: '状态', key: 'status',
  render: (item) => {
    const now = Moment.now();
    const start_at = Moment(item.start_at);
    const end_at = Moment(item.end_at);
    if (start_at > now) {
      return <div className="course-waiting">未开始</div>
    } else if (end_at < now) {
      return <div className="course-finished">结束</div>
    } else {
      return <div className="course-ongoing">正在上课</div>
    }
  }
}, {
  title: '班级', key: 'classname',
  render: (item) => <Link to={`/class/${item.class_id}`}>
    {item.class_no}<br/>
    {item.class_name}（第{item.order}次）
  </Link>
}, {
  title: '课程', key: 'name',
  dataIndex: 'name'
}, {
  title: '上课教室', key: 'classroom',
  render: (item) => <div>{item.classroom_name}</div>
}, {
  title: '开始时间', key: 'start_at',
  render: (item) => <div>{Moment(item.start_at).format('YYYY-MM-DD HH:mm')}</div>
}, {
  title: '结束时间', key: 'ent_at',
  render: (item) => <div>{Moment(item.end_at).format('YYYY-MM-DD HH:mm')}</div>
}, {
  title: '签到', key: 'signin',
  render: (item) => {
    return (
      <div>
        <Button
          type='primary'
          onClick={() => {
            ClassSignInStore.targetClassRecord = item.id;
            ClassSignInStore.fetchStudents(item.id)
              .then(() => {
                ClassSignInStore.visible = true;
              });
          }}>
          签到
        </Button>
        <Divider type="vertical"/>
        <Button type="primary" onClick={() => {
          ClassRecordFeedbackStore.crId = item.id;
          ClassRecordFeedbackStore.modalVisible = true;
          ClassRecordFeedbackStore.fetchClassRecordFeedback();
        }}>
          反馈
        </Button>
      </div>
    )
  }
}];

@observer
class GenericClassRecordList extends React.Component {
  render() {
    return (
      <div>
        <Table
          rowKey={item => item.id}
          columns={courseRecordColumns}
          dataSource={this.props.courseRecords}
          loading={this.props.loading}
        />
        <ClassSignInModal/>
        <ClassRecordFeedbackModal/>
      </div>
    );
  }
}

export default GenericClassRecordList;
