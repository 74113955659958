import React from 'react';
import {observer} from "mobx-react";
import {Row, Col, Tabs} from 'antd';

import WrappedStudentEditor from "../components/StudentEditor";
import StudentDetail from "../components/StudentDetail";
import StudentOverview from "../components/StudentOverview";

import '../style.css';
import StudentStore from "../stores/StudentStore";


@observer
class StudentEditorContainer extends React.Component{
  componentWillMount = () => {
    const {studentId} = this.props.match.params;
    StudentStore.fetchStudent(studentId);
  };

  render(){
    const {studentId} = this.props.match.params;
    return (
      <Row>
        <Col span={24}>
          <h3>{StudentStore.studentDetail.name}</h3>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="总览" key="1">
              <StudentOverview studentId={studentId}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="课程" key="2">
              <StudentDetail studentId={studentId}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="编辑" key="3">
              <WrappedStudentEditor studentId={studentId}/>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>

    )
  }
}

export default StudentEditorContainer;
