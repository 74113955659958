import React from 'react';
import {Button, Card, Col, Divider, Progress, Row,} from "antd";
import {observer} from "mobx-react";
import StudentOverviewStore from "../stores/StudentOverviewStore";
import StudentStore from "../stores/StudentStore";


@observer
class StudentOverview extends React.Component {
  componentWillMount = () => {
    const {studentId} = this.props;
    StudentOverviewStore.fetchStudentOverview(studentId);
  };

  render() {
    const {studentId} = this.props;
    const {course_statistics} = StudentOverviewStore.studentOverview;

    return (
      <div>
        <Card className="student-detail-card" title="课销">
          <Row className="student-overview-course-stat">
            <Col span={8}>
              <h3>已上课时 {course_statistics.past} 节</h3>
            </Col>
            <Col span={8}>
              <h3>未上课时 {course_statistics.future} 节</h3>
            </Col>
            <Col span={8}>{
              course_statistics.current ?
                <h3>当前课程：
                  <span className="student-overview-current-course">{course_statistics.current}</span>
                </h3> :
                <h3>当前无课程</h3>
            }</Col>
            <Col span={8}>
              <h3>已上课时 {course_statistics.past_time} 分钟</h3>
            </Col>
            <Col span={8}>
              <h3>未上课时 {course_statistics.future_time} 分钟</h3>
            </Col>
            <Col span={8}>{
              course_statistics.next ?
                <h3>下节课：
                  <span className="student-overview-next-course">{course_statistics.next}</span>
                </h3> :
                <h3>课程已上完</h3>
            }</Col>
            <Col span={24}>
              <Progress
                percent={parseFloat((100 * course_statistics.past / (course_statistics.past + course_statistics.future)).toFixed(2))}
                status="active"/>
            </Col>
          </Row>
          <Row>
            <Divider className="student-overview-divider" orientation="left">进度明细</Divider>
            {course_statistics.types.map((item) => {
              if (item.past + item.future > 0)
                return (<Col key={item.label} span={8}>
                  <h3>{item.label}: {(item.past_time / 60).toFixed(1)}/{((item.past_time + item.future_time) / 60).toFixed(1)} 小时</h3>
                </Col>);
              else
                return '';
            })}
          </Row>
          <Row>
            <Divider className="student-overview-divider" orientation="left">付费情况</Divider>
            <h3>
              总课程 {((
              course_statistics.past_time + course_statistics.future_time
            ) / 60).toFixed(1)} 小时，
              已付费课程 {StudentStore.studentDetail.paid_course} 小时</h3>
          </Row>
          <Row>
            <Divider className="student-overview-divider" orientation="left">备注</Divider>
            <p>{StudentStore.studentDetail.comment}</p>
          </Row>
        </Card>
        <Card className="student-detail-card" title="课表信息下载">
          <a
            href={`/api/student/${studentId}/get_schedule/`}
            download={`${studentId}课表`}
          ><Button type='primary'>下载未来课表</Button></a>
          &nbsp;&nbsp;
          <a
            href={`/api/student/${studentId}/get_schedule/?past=1`}
            download={`${studentId}总课表`}
          ><Button type=''>下载全部课表</Button></a>
        </Card>
      </div>
    );
  }
}

export default StudentOverview;
