import React from 'react';
import {observer} from 'mobx-react';
import moment from 'moment';

import {
  Modal, Button, Form, Row, Col, message, Input, Icon, Select, DatePicker, Tooltip
} from 'antd';
import ClassRecordStore from "../stores/ClassRecordStore";
import ClassStore from "../stores/ClassStore";
import TeacherStore from "../../teacher/stores/TeacherStore";
import ClassroomStore from "../../campus/stores/ClassroomStore";
import ClassRecordBatchCreateStore from "../stores/ClassRecordBatchCreateStore";
import {ClassRecordChoiceNames, ClassRecordChoices} from "../constants";

const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

@observer
class ClassRecordModal extends React.Component {
  componentDidMount = () => {
    ClassRecordBatchCreateStore.allowSave = false;
  };

  handleCancel = () => {
    ClassRecordStore.showClassRecordEditModal = false;
  };

  handleOk = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.start_at = values.start_at.format();
        if (ClassRecordStore.isCreate || ClassRecordStore.isCopy) {
          values._class = ClassStore.classDetail.class_id;
          ClassRecordStore.createClassRecord(values)
            .then(() => {
              message.success('Create success!');
              ClassRecordStore.fetchClassRecordList(ClassStore.classDetail.class_id);
              ClassRecordStore.showClassRecordEditModal = false;
            })
            .else((res) => {
              message.error(`Create failed! Msg: ${res.response.toString()}`);
            });
        } else {
          const {id} = ClassRecordStore.classRecordDetail;
          ClassRecordStore.updateClassRecord(id, values)
            .then(() => {
              message.success('Update success!');
              ClassRecordStore.fetchClassRecordList(ClassStore.classDetail.class_id);
              ClassRecordStore.showClassRecordEditModal = false;
            })
            .else((res) => {
              message.error(`Update failed! Msg: ${res.response.toString()}`);
            });
        }
      }
    });
  };

  handleCheck = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      values.id = ClassRecordStore.classRecordDetail.id;
      values._class = ClassStore.classDetail.class_id;
      ClassRecordBatchCreateStore.batchCheck([values]);
    });
  };

  render() {
    const {isCreate, isCopy} = ClassRecordStore.isCreate;

    const {
      getFieldDecorator, getFieldsError,
    } = this.props.form;

    return (
      <Modal
        visible={ClassRecordStore.showClassRecordEditModal}
        title={isCreate ? '创建课时' : isCopy ? '复制课时' : '修改课时'}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>取消</Button>,
          <Button
            key="check" type="danger" loading={ClassRecordStore.loadingModal}
            onClick={this.handleCheck} disabled={hasErrors(getFieldsError())}>
            检查
          </Button>,
          <Button
            key="submit" type="primary" loading={ClassRecordStore.loadingModal}
            onClick={this.handleOk} disabled={
            !ClassRecordBatchCreateStore.allowSave || hasErrors(getFieldsError())}
          >
            保存
          </Button>,
          <Tooltip key="tooltip" placement="top" title="请先检查再保存">
            &nbsp;&nbsp;<Icon type="question-circle"/>
          </Tooltip>,
        ]}
        destroyOnClose={true}
      >
        <Form
          layout="vertical"
          onSubmit={this.handleOk}>
          <Row gutter={12}>
            <Col sm={24} md={12}>
              <FormItem label="课程名">
                {getFieldDecorator('name', {
                  initialValue: isCreate ? '' : ClassRecordStore.classRecordDetail.name,
                  rules: [{required: true, message: '请输入课程名'}],
                })(
                  <Input
                    prefix={<Icon type="team" style={{color: 'rgba(0,0,0,.25)'}}/>}
                    placeholder="课程名"
                  />
                )}
              </FormItem>
            </Col>

            <Col sm={24} md={12}>
              <FormItem label="开始时间">
                {getFieldDecorator('start_at', {
                  initialValue: isCreate ? null : moment(ClassRecordStore.classRecordDetail.start_at),
                  rules: [{required: true, message: '请输入课程开始时间'}],
                })(
                  <DatePicker
                    showTime={{format: 'HH:mm'}}
                    format="YYYY-MM-DD HH:mm"
                    placeholder="选择课程开始时间"
                  />
                )}
              </FormItem>
            </Col>

            <Col sm={24} md={12}>
              <FormItem label="持续时间（分钟）">
                {getFieldDecorator('duration', {
                  initialValue: isCreate ? '' : ClassRecordStore.classRecordDetail.duration,
                  rules: [{required: true, message: '请输入课程持续时间'}],
                })(
                  <Input prefix={<Icon type="team" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Duration"/>
                )}
              </FormItem>
            </Col>

            <Col sm={24} md={12}>
              <FormItem label="Teacher">
                {getFieldDecorator('teacher', {
                  initialValue: isCreate ? '' : ClassRecordStore.classRecordDetail.teacher,
                })(
                  <Select
                    showSearch
                    placeholder="Select teacher"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {TeacherStore.teacherOptions}
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col sm={24} md={12}>
              <FormItem label="教室">
                {getFieldDecorator('classroom', {
                  initialValue: isCreate ? '' : ClassRecordStore.classRecordDetail.classroom,
                })(
                  <Select
                    showSearch
                    placeholder="Select teacher"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {ClassroomStore.classroomOptions}
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col sm={24} md={12}>
              <FormItem label="类型">
                {getFieldDecorator('type', {
                  initialValue: isCreate ? 'NULL' : ClassRecordStore.classRecordDetail.type,
                })(
                  <Select>
                    {Object.keys(ClassRecordChoices).map(
                      item => <Select.Option key={item}>{
                        ClassRecordChoiceNames[item]
                      }</Select.Option>
                    )}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
}

const WrappedClassRecordModal = Form.create()(ClassRecordModal);

export default WrappedClassRecordModal;
