import React from 'react';
import {Button, Col, Divider, Row} from 'antd';
import {observer} from "mobx-react";
import ClassStore from "../stores/ClassStore";


@observer
class ClassSchedule extends React.Component {

  render() {
    const { classId } = this.props;
    return (
      <Row>
        <Col span={24}>
          <h2>课表信息下载</h2>
        </Col>
        <Divider />
        <Col span={24}>
          <a
            href={`/api/class/${classId}/get_schedule_table/?past=1`}
            download={`${ClassStore.classDetail.name}课表`}
          ><Button type='primary'>下载课程安排表</Button></a>
          &nbsp;&nbsp;
          <a
            href={`/api/class/${classId}/get_students_table/`}
            download={`${ClassStore.classDetail.name}学生列表`}
          ><Button type=''>下载上课学生表</Button></a>
        </Col>
      </Row>
    );
  }
}

export default ClassSchedule;
