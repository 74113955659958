import React from "react";
import {observer} from "mobx-react";

import {Card} from 'antd';
import {Chart, Geom, Axis, Tooltip} from "bizcharts";

import ClassroomMonitorStore from "../stores/ClassroomMonitorStore";


@observer
class ClassroomUsagesGraph extends React.Component {
  render() {
    const cols = {
      usage: {
        min: 0
      },
      date: {}
    };
    return (
      <Card
        loading={ClassroomMonitorStore.loading}
        title="教室总体使用情况表"
      >
        <Chart
          height={400}
          data={ClassroomMonitorStore.classroomDashboard.classroom_usages}
          scale={cols} forceFit>
          <Axis name="date"/>
          <Axis
            name="usage"
            label={{
              formatter: val => `${val} 分钟`
            }}
          />
          <Tooltip
            itemTpl={'<li data-index={index}>'
            + '<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>'
            + '{name}: {value} 分钟'
            + '</li>'}
            crosshairs={{
              type: "y"
            }}
          />
          <Geom type="line" position="date*usage" size={2}/>
          <Geom
            type="point"
            position="date*usage"
            size={4}
            shape={"circle"}
            style={{
              stroke: "#fff",
              lineWidth: 1
            }}
          />
        </Chart>
      </Card>
    );
  }
}

export default ClassroomUsagesGraph;
