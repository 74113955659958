import {action, observable} from 'mobx';
import FetchProvider from '../../../shared/RequestProvider';

class ClassRecordStore {
  @observable isCreate = false;
  @observable isCopy = false;
  @observable loading = false;
  @observable loadingModal = false;
  @observable showClassRecordEditModal = false;
  @observable classId = 0;
  @observable pagination = {
    total: 0,
    current: 1,
    next: null,
    previous: null,
    pageSize: 1,
  };

  @observable classRecordList = [];
  @action fetchClassRecordList = (classId) => {
    this.loading = true;
    this.classId = classId;
    return FetchProvider.get(`/api/classrecord/?_class=${classId}`)
      .then((res) => {
        if (res.data) {
          this.classRecordList = res.data;
          this.loading = false;
        }
      });
  };

  @action refreshClassRecordList = () => {
    if (this.classId) {
      this.fetchClassRecordList(this.classId);
    }
  };

  @observable classRecordDetail = {};
  @action fetchClassRecord = (classrecord_id) => {
    this.loadingModal = true;
    return FetchProvider.get(`/api/classrecord/${classrecord_id}/`)
      .then((res) => {
        if (res.data) {
          this.classRecordDetail = res.data;
          this.loadingModal = false;
        }
      });
  };
  @action updateClassRecord = (classrecord_id, update_data) => {
    this.loadingModal = true;
    return FetchProvider.patch(`/api/classrecord/${classrecord_id}/`, update_data)
      .then((res) => {
        if (res.data) {
          this.classRecordDetail = res.data;
          this.loadingModal = false;
        }
      });
  };
  @action createClassRecord = (classrecord_data) => {
    this.loadingModal = true;
    return FetchProvider.post('/api/classrecord/', classrecord_data)
      .then((res) => {
        if (res.data) {
          this.classRecordDetail = res.data;
          this.loadingModal = false;
        }
      });
  };
  @action deleteClassRecord = (classrecord_id) => {
    return FetchProvider.delete(`/api/classrecord/${classrecord_id}`);
  };
}

export default new ClassRecordStore();
