import React from 'react';
import ClassroomUsagesGraph from "../components/ClassroomUsagesGraph";
import ClassroomDistributionField from "../components/ClassroomDistributionField";
import ClassroomLowUsageList from "../components/ClassroomLowUsageList";
import ClassroomMonitorStore from "../stores/ClassroomMonitorStore";

class ClassroomMonitorContainer extends React.Component {
  componentWillMount = () => {
    ClassroomMonitorStore.fetchClassroomDashboard();
  };

  render() {
    return (
      <div>
        <ClassroomDistributionField/>
        <br/>
        <ClassroomUsagesGraph/>
        <br/>
        <ClassroomLowUsageList/>
      </div>
    );
  }
}

export default ClassroomMonitorContainer;
