import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {
  Layout, Menu, Icon,
} from 'antd';

import './style/layout.css';
import AppRouter from "./AppRouter";

const {Header, Content, Sider} = Layout;

class LayoutWithSider extends React.Component {
  getSelectKey = () => {
    const li = this.props.location.pathname.split('/');
    if (li.length > 0) {
      return `/${li[1]}`;
    } else {
      return '/';
    }
  };

  render() {
    return (
      <Layout>
        <Header className="header">
          <div className="sider-logo"/>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[]}
            style={{lineHeight: '64px'}}
          >
            <Menu.Item key="1">About</Menu.Item>
          </Menu>
        </Header>
        <Layout>
          <Sider
            collapsedWidth="0"
            breakpoint="lg"
            width={200}
            style={{background: '#fff'}}
          >
            <Menu
              mode="inline"
              selectedKeys={[this.getSelectKey()]}
              style={{height: '100%', borderRight: 0}}
            >
              <Menu.Item key="/dashboard">
                <NavLink to="/dashboard">
                  <span><Icon type="user"/>Dashboard</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/student">
                <NavLink to="/student">
                  <span><Icon type="user"/>Student</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/teacher">
                <NavLink to="/teacher">
                  <span><Icon type="user"/>Teacher</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/class">
                <NavLink to="/class">
                  <span><Icon type="user"/>Class</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/location">
                <NavLink to="/location">
                  <span><Icon type="read"/>Campus</span>
                </NavLink>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="main-layout">
            <Content className="layout-content">
              <AppRouter/>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

export default withRouter(LayoutWithSider);
