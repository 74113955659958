import {action, observable} from 'mobx';
import FetchProvider from "../../../shared/RequestProvider";

class MonthCourseStore {
  @observable loading = false;
  @observable monthCourse = [];
  @observable location = 0;

  @observable cached_params = {};

  @action refreshCourses = () => {
    this.fetchMonthCourses(this.cached_params.year, this.cached_params.month);
  };

  @action fetchMonthCourses = (year, month) => {
    const params = {year, month, location: this.location};
    this.cached_params = params;

    this.loading = true;
    return FetchProvider.get('/api/classrecord/get_crs_by_month/', {params})
      .then((res) => {
        this.monthCourse = res.data;
        this.loading = false;
      });
  };

}

export default new MonthCourseStore();
