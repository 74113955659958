import React from 'react';
import {observer} from "mobx-react";
import {Card, Col, Row} from "antd";

import {
  Chart,
  Geom,
  Axis,
  Tooltip,
  Coord,
  Label,
  Legend,
  Guide,
} from "bizcharts";


import ClassroomMonitorStore from "../stores/ClassroomMonitorStore";


@observer
class ClassroomDistributionField extends React.Component{
  render() {
    const cols = {
      percent: {
        formatter: val => {
          val = (val * 100).toFixed(2) + "%";
          return val;
        }
      }
    };

    return (
      <Card title="教室分布" loading={ClassroomMonitorStore.loading}>
        <Row>
          <Col md={24} lg={24}>
            <Chart
              height={window.innerHeight / 3}
              data={ClassroomMonitorStore.resolvedClassroomDistribution}
              scale={cols}
              padding={[30, 60, 30, 30]}
              forceFit
            >
              <Coord type={"theta"} radius={0.75} innerRadius={0.6} />
              <Axis name="percent" />
              <Legend
                position="right"
                offsetY={-window.innerHeight / 2 + 220}
                offsetX={-240}
              />
              <Tooltip
                showTitle={false}
                itemTpl="<li><span style=&quot;background-color:{color};&quot; class=&quot;g2-tooltip-marker&quot;></span>{name}: {value}</li>"
              />
              <Guide>
                <h2>共有{ClassroomMonitorStore.classroomCount}间教室</h2>
              </Guide>
              <Geom
                type="intervalStack"
                position="percent"
                color="location"
                tooltip={[
                  "location*percent",
                  (location, percent) => {
                    percent = percent * 100 + "%";
                    return {
                      name: location,
                      value: percent
                    };
                  }
                ]}
                style={{
                  lineWidth: 1,
                  stroke: "#fff"
                }}
              >
                <Label
                  content="percent"
                  formatter={(val, location) => {
                    return location.point.location + ": " + val;
                  }}
                />
              </Geom>
            </Chart>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default ClassroomDistributionField;
