import React from 'react';
import {Link} from "react-router-dom";

import {observer} from "mobx-react";

import {Table} from "antd";

const columns = [{
  title: '学生 ID',
  key: 'student_id',
  render: item => <Link to={`/student/${item.student_id}`}>{item.student_id}</Link> ,
}, {
  title: '姓名',
  dataIndex: 'name',
}, {
  title: '英文名',
  dataIndex: 'eng_name',
}, {
  title: '电话',
  dataIndex: 'phone',
}, {
  title: '操作',
  dataIndex: 'action',
}];

@observer
class StudentSelectList extends React.Component {
  render() {
    return (
      <div>
        <Table
          rowKey={item => item.student_id}
          columns={columns}
          dataSource={this.props.dataSource}
          loading={this.props.loading}
        />
      </div>
    )
  }
}

export default StudentSelectList;
