import React from 'react';
import {Row, Col, Button, Select, Divider, Radio} from 'antd';

import StudentList from "../components/StudentList";
import StudentStore from "../stores/StudentStore";

class StudentListContainer extends React.Component{
  onStudentStatusChange = e => {
    StudentStore.searchStudentStatus = e.target.value.toString();
    StudentStore.fetchStudentList();
  };

  onTypeSelectChange = value => {
    StudentStore.searchStudentType = value.toString();
    StudentStore.fetchStudentList();
  };

  render(){
    return (
      <Row>
        <Col span={8}>
          <h1>学生列表</h1>
        </Col>
        <Col span={16}>
          <div style={{position: 'absolute', right: 0, top: 10}}>
            <Radio.Group onChange={this.onStudentStatusChange} defaultValue="1">
              <Radio.Button value="1">活跃</Radio.Button>
              <Radio.Button value="0">毕业</Radio.Button>
              <Radio.Button value="-1">异常</Radio.Button>
            </Radio.Group>
            &nbsp;
            <Select
              style={{ width: 100 }}
              placeholder="选择类型"
              onChange={this.onTypeSelectChange}
            >
              <Select.Option value="">全部</Select.Option>
              <Select.Option value="i">雅思</Select.Option>
              <Select.Option value="t">托福</Select.Option>
              <Select.Option value="a">A-Level</Select.Option>
            </Select>
            <Divider type="vertical"/>
            <Button onClick={() => {StudentStore.fetchStudentList()}}>刷新</Button>&nbsp;&nbsp;
            <a href="/admin/students/student/add/"
               target="_blank">
              <Button type="primary">添加</Button></a>
          </div>
        </Col>
        <Col span={24}>
          <StudentList/>
        </Col>
      </Row>
    )
  }
}

export default StudentListContainer;
