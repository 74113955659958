import React from 'react';
import { Row, Col } from 'antd';

import LocationList from "../components/LocationList";

class LocationListContainer extends React.Component{

  render(){
    return (
      <Row>
        <Col span={24}>
          <h1>校区列表</h1>
        </Col>
        <Col span={24}>
          <LocationList/>
        </Col>
      </Row>

    )
  }
}

export default LocationListContainer;
