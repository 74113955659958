import React from 'react';
import {observer} from "mobx-react";
import {Col, Row, Select, Spin} from 'antd';
import Moment from 'moment';

import CourseCalendar from "../../class/components/CourseCalendar";
import MonthCourseStore from "../stores/MonthCourseStore";
import LocationStore from '../../campus/stores/LocationStore';


@observer
class MonthCalendar extends React.Component {
  componentDidMount = () => {
    const now = Moment();
    MonthCourseStore.fetchMonthCourses(now.year(), now.month() + 1);
    LocationStore.fetchLocationOptions();
  };

  onDateChange = (moment_instance) => {
    MonthCourseStore.fetchMonthCourses(moment_instance.year(), moment_instance.month() + 1);
  };

  onLocationChange = val => {
    MonthCourseStore.location = parseInt(val.toString());
    MonthCourseStore.refreshCourses();
  };

  getSelectOptions = () => {
    return [<Select.Option key={0} value={0}>全部</Select.Option>].concat(LocationStore.locationOptions);
  };

  render() {
    return (
      <Spin spinning={MonthCourseStore.loading} size="large">
        <Row>
          <Col span={24}>
            <Select placeholder="选择校区" style={{width: 120}} onChange={this.onLocationChange}>
              {this.getSelectOptions()}
            </Select>
          </Col>
        </Row>
        <CourseCalendar
          dateAsKey
          onChange={this.onDateChange}
          dailyCourseList={MonthCourseStore.monthCourse}
        />
      </Spin>
    );
  }
}

export default MonthCalendar;
