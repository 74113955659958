import {action, observable} from 'mobx';
import FetchProvider from '../../../shared/RequestProvider';

class ClassRecordFeedbackStore {
  @observable loading = false;
  @observable modalVisible = false;
  @observable crId = 0;
  @observable feedbackInfo = {};

  @observable updateFeedbackTextFieldMethod = undefined;

  @action fetchClassRecordFeedback = () => {
    if (this.crId === 0) {
      return;
    }
    this.loading = true;
    return FetchProvider.get(`/api/classrecord_feedback/${this.crId}/`)
      .then((res) => {
        this.loading = false;
        this.feedbackInfo = res.data;
      })
  };

  @action updateClassRecordFeedback = (feedback) => {
    if (this.crId === 0) {
      return;
    }
    this.loading = true;
    const data = {
      feedback,
    };
    return FetchProvider.patch(`/api/classrecord_feedback/${this.crId}/`, data)
      .then((res) => {
        this.loading = false;
        this.feedbackInfo = res.data;
      })
  };

  @action reset = () => {
    this.crId = 0;
    this.modalVisible = false;
    this.feedbackInfo = {};
  }
}

export default new ClassRecordFeedbackStore();
